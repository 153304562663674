export const enum IFillVariant {
    Blank,
    Fill
}

export const enum InjuryPriority {
    Low = "Low",
    Medium = "Medium",
    High = "High",
}

export const enum ChatSocketEnum {
    CONNECT = "chat:connect",
    SUCCESSFULLY_CONNECT = "chat:successfully_connect",
    NEW_MESSAGE = "chat:new_message",
    SEND_MESSAGE = "chat:send_message",
    RETURN_MESSAGE = "chat:return_message",
    MESSAGES_HAVE_BEEN_READ = 'chat:messages_have_been_read',
    ERROR = "error",
}

export const enum HomeSocketEnum {
    NEW_MESSAGE = 'home:new_message',
    CONNECT = 'home:connect',
    SUCCESSFULLY_CONNECT = 'home:successfully_connect',
    UPDATE_HAS_BEEN_READ = 'update_has_been_read',
    ERROR = "error",
}
