import ReqError from "@source/api/reqError";
import { api } from "@source/api/http";
import {
  ICreateResidentRequest,
  ICreateUpdateRequest,
  ICreateUpdateResponse,
  IGetResidentsRequest,
  IGetResidentUpdatesRequest,
  IPatchUpdateRequest,
  IPatchUpdateResponse,
  ICreateResidentResponse,
  IGetResidentsResponse,
  IGetAllUpdatesRequest,
  IGetAllUpdatesResponse,
  IGetResidentRequest,
  ICreateResidentContactResponse,
  IDeleteResidentContactCommentRequest,
  ICreateResidentContactCommentRequest,
  IDeleteUpdateRequest,
  IGetUpdateRequest,
  IEditResidentRequest,
  ICreateFamilyMemberRequest,
  IEditFamilyMemberRequest,
  IDeleteFamilyMemberCommunicationResponse,
  IAddFamilyMemberRequest,
  IAddFamilyMemberResponse,
  IDisableResidentResponse,
  IDeleteResidentPhotoResponse,
  IEditFamilyMemberRelationRequest,
  IEditFamilyMemberRelationResponse,
  IGetDisabledResidentsRequest,
  IGetResidentUpdatesResponse,
  IGetResidentResponse,
  IGetResidentsCountResponse,
  IGetUpdateReadersResponse,
  IGetUpdateReadersRequest,
  IGetArchiveResidentResponse,
  IDownloadResidentUpdateHistoryRequest,
  IDisableResidentRequest,
  IDownloadUnreadUpdateHistoryRequest,
  IUpdateUploadFilesReq,
  IUpdateFile,
  IUpdateDeleteFileReq,
  IDownloadHomeUpdateHistoryRequest, IGetTotalUpdatesSent, IEditFamilyMemberParticipationRequest
} from "@source/api/models/residentModel";
import { getSectionNameById, getSubSectionNameById, getPriorityNameById, hydrateSchema } from "@source/pages/HomePage/homeRouter/updateTab/updateSchema/updateFormUtils";
import { UpdatePrioritiesId, SectionsId, SubSectionsId } from "@source/pages/HomePage/homeRouter/updateTab/updateSchema/updateFormModels";
import axios, { AxiosResponse } from "axios";

export const DEFAULT_UPDATES_LIMIT = 30;

export default class ResidentService {
  static async createResident(newResident: ICreateResidentRequest) {
    try {
      return (await api.post<ICreateResidentResponse>(`nursingHome/${newResident.nursingHomeId}/resident`, {...newResident})).data
    } catch (e: any) {
      throw ReqError.createResidentError(e.response)
    }
  }

  static async editResident(newResident: IEditResidentRequest, residentId: string) {
    try {
      return (await api.put<ICreateResidentResponse>(`nursingHome/${newResident.nursingHomeId}/resident/${residentId}`, {...newResident})).data
    } catch (e: any) {
      throw ReqError.editResidentError(e.response)
    }
  }

  static async deletResidentPhoto(nursingHomeId: string, residentId: string) {
    try {
      return (await api.delete<IDeleteResidentPhotoResponse>(`nursingHome/${nursingHomeId}/resident/${residentId}/photo`)).data
    } catch (e: any) {
      throw ReqError.editResidentError(e.response)
    }
  }

  static async disableResident(nursingHomeId : string, residentId: string, req: IDisableResidentRequest) {
    try {
      return (await api.patch<IDisableResidentResponse>(`nursingHome/${nursingHomeId}/resident/${residentId}/disable`, req)).data
    } catch (e: any) {
      throw ReqError.editResidentError(e.response)
    }
  }

  static async addFamilyMember(familyMember: IAddFamilyMemberRequest, familyMemberId: string) {
    try {
      return (await api.post<IAddFamilyMemberResponse>(`family-member/${familyMemberId}/residents`, {...familyMember})).data
    } catch (e: any) {
      throw ReqError.addFamilyMemberError(e.response)
    }
  }

  static async createFamilyMember(newFamilyMember: ICreateFamilyMemberRequest) {
    try {
      return (await api.post<ICreateResidentResponse>(`auth/family-member/register`, {...newFamilyMember})).data
    } catch (e: any) {
      throw ReqError.createFamilyMemberError(e.response)
    }
  }

  static async editFamilyMember(editedFamilyMember: IEditFamilyMemberRequest, familyMemberId: string) {
    try {
      return (await api.put<ICreateResidentResponse>(`family-member/${familyMemberId}`, {...editedFamilyMember})).data
    } catch (e: any) {
      throw ReqError.editFamilyMemberError(e.response)
    }
  }

  static async editFamilyMemberParticipation(editedParticipation: IEditFamilyMemberParticipationRequest, familyMemberId: string) {
    try {
      return (await api.patch<ICreateResidentResponse>(`family-member/${familyMemberId}/non-participant`, {...editedParticipation})).data
    } catch (e: any) {
      throw ReqError.editFamilyMemberError(e.response)
    }
  }

  static async editFamilyMemberRelation(relation: IEditFamilyMemberRelationRequest, residentId: string, familyMemberId: string) {
    try {
      return (await api.post<IEditFamilyMemberRelationResponse>(`family-member/${familyMemberId}/residents/${residentId}/relation`, {...relation})).data
    } catch (e: any) {
      throw ReqError.editFamilyMemberError(e.response)
    }
  }

  static async deleteFamilyMemberCommunication(familyMemberId: string, residentId: string) {
    try {
      return (await api.delete<IDeleteFamilyMemberCommunicationResponse>(`family-member/${familyMemberId}/residents/${residentId}`)).data
    } catch (e: any) {
      throw ReqError.disableFamilyMember(e.response)
    }
  }

  static async updateResidentPhoto(req: FormData, nursingHomeId: string, residentId: string): Promise<AxiosResponse<any>> {
    try {
      return(await api.post(`nursingHome/${nursingHomeId}/resident/${residentId}/photo`, req)).data;
    } catch (e: any) {
      throw ReqError.updateResidentPhotoError(e.response)
    }
  }

  static async getResidents({nursingHomeId, searchString}: IGetResidentsRequest) {
    try {
      return (await api.get<IGetResidentsResponse>(`nursingHome/${nursingHomeId}/resident?searchString=${searchString || ""}&limit=1000`)).data
    } catch (e: any) {
      throw ReqError.getResidentsError(e.response)
    }
  }

  static async getResidentsCount(nursingHomeId: string) {
    try {
      return (await api.get<IGetResidentsCountResponse>(`nursingHome/${nursingHomeId}/residents/count`)).data
    } catch (e: any) {
      throw ReqError.getResidentsCountError(e.response)
    }
  }

  static async getDisabledResidents({nursingHomeId, searchString}: IGetDisabledResidentsRequest) {
    try {
      return (await api.get<IGetResidentsResponse>(`nursingHome/${nursingHomeId}/residents/disabled?searchString=${searchString || ""}&limit=1000`)).data
    } catch (e: any) {
      throw ReqError.getDisabledResidentsError(e.response)
    }
  }

  static async getDisabledResident({nursingHomeId, residentId}: IGetResidentRequest) {
    try {
      return (await api.get<IGetArchiveResidentResponse>(`nursingHome/residents/${residentId}/disabled`)).data
    } catch (e: any) {
      throw ReqError.getResidentError(e.response)
    }
  }

  static async getResident({nursingHomeId, residentId}: IGetResidentRequest) {
    try {
      return (await api.get<IGetResidentResponse>(`nursingHome/${nursingHomeId}/resident/${residentId}`)).data
    } catch (e: any) {
      throw ReqError.getResidentError(e.response)
    }
  }

  static async downloadResidentUpdateHistory(nursingHomeId: string, residentId: string, req: IDownloadResidentUpdateHistoryRequest) {
    try {
      return (await api.get(`nursingHome/${nursingHomeId}/resident/${residentId}/update/documents/generate`, {responseType: 'arraybuffer', params: {...req,}})).data;
    } catch (e: any) {
      throw ReqError.downloadResidentUpdateHistoryError(e)
    }
  }

  static async downloadHomeUpdateHistory(nursingHomeId: string, req: IDownloadHomeUpdateHistoryRequest) {
    try {
      return (await api.get(`nursingHome/${nursingHomeId}/resident/update/documents/generate-full`, {responseType: 'arraybuffer', params: {...req,}})).data;
    } catch (e: any) {
      throw ReqError.downloadResidentUpdateHistoryError(e)
    }
  }

  static async downloadUnreadUpdateHistory(nursingHomeId: string, req: IDownloadUnreadUpdateHistoryRequest) {
    try {
      return (await api.get(`nursingHome/${nursingHomeId}/update/unread-document`, {responseType: 'arraybuffer', params: {...req}})).data;
    } catch (e: any) {
      throw ReqError.downloadResidentUpdateHistoryError(e)
    }
  }

  static async createResidentContactComment({familyMemberId , body}: ICreateResidentContactCommentRequest) {
    try {
      return (await api.patch<ICreateResidentContactResponse>(`family-member/${familyMemberId}/comment`, {...body})).data
    } catch (e: any) {
      throw ReqError.createResidentContactCommentError(e.response)
    }
  }

  static async deleteResidentContactComment({familyMemberId, body}: IDeleteResidentContactCommentRequest) {
    try {
      return (await api.delete<Array<ICreateResidentContactResponse>>(`family-member/${familyMemberId}/comment`, {
        data: {
          ...body,
        }
      })).data
    } catch (e: any) {
      throw ReqError.deleteResidentContactCommentError(e.response)
    }
  }

  static async createUpdate({nursingHomeId, residentId, body}: ICreateUpdateRequest) {
    try {
      const data = (await api.post<ICreateUpdateResponse>(`nursingHome/${nursingHomeId}/resident/${residentId}/update`, body)).data;
      return completeUpdateResponse(data)
    } catch (e: any) {
      throw ReqError.createUpdateError(e.response)
    }
  }
  
  static async updateUploadFiles({nursingHomeId, residentId, updateId, body}: IUpdateUploadFilesReq) {
    try {
      return (await api.post(`nursingHome/${nursingHomeId}/resident/${residentId}/update/${updateId}/upload-files`, body)).data;
    } catch (e: any) {
      throw ReqError.updateUploadFilesError(e.response)
    }
  }

  static async updateUploadFile({nursingHomeId, residentId, updateId, body}: IUpdateUploadFilesReq) {
    try {
      return (await api.post<IUpdateFile[]>(`nursingHome/${nursingHomeId}/resident/${residentId}/update/${updateId}/upload-file`, body)).data;
    } catch (e: any) {
      throw ReqError.updateUploadFileError(e.response)
    }
  }

  static async updateDeleteFile({nursingHomeId, residentId, updateId, fileKey}: IUpdateDeleteFileReq) {
    try {
      return (await api.delete(`nursingHome/${nursingHomeId}/resident/${residentId}/update/${updateId}/files/${fileKey}`)).data
    } catch (e: any) {
      throw ReqError.deleteUpdateError(e.response)
    }
  }

  static async getResidentUpdates({nursingHomeId, residentId, page}: IGetResidentUpdatesRequest) {
    try {
      const data = (await api.get<IGetResidentUpdatesResponse>(`nursingHome/${nursingHomeId}/resident/${residentId}/update?limit=${DEFAULT_UPDATES_LIMIT}&page=${page || "0"}`)).data
      return {
        updates: data.updates.length ? data.updates : [],
        totalCount: data.totalCount
      }
    } catch (e: any) {
      throw ReqError.getResidentUpdatesError(e.response)
    }
  }

  static async getDisabledResidentUpdates({nursingHomeId, residentId, page}: IGetResidentUpdatesRequest) {
    try {
      const data = (await api.get<IGetResidentUpdatesResponse>(`nursingHome/${nursingHomeId}/resident/${residentId}/update/disabled?limit=${DEFAULT_UPDATES_LIMIT}&page=${page || "0"}`)).data
      return {
        updates: data.updates.map((update) => completeUpdateResponse(update)),
        totalCount: data.totalCount
      }
    } catch (e: any) {
      throw ReqError.getResidentUpdatesError(e.response)
    }
  }

  static async getUpdate({nursingHomeId, residentId, updateId}: IGetUpdateRequest) {
    try {
      const data = (await api.get(`nursingHome/${nursingHomeId}/resident/${residentId}/update/${updateId}`)).data
      return data;
    } catch (e: any) {
      throw ReqError.getUpdateError(e.response)
    }
  }

  static async deleteUpdate({nursingHomeId, residentId, updateId}: IDeleteUpdateRequest) {
    try {
      return (await api.delete(`nursingHome/${nursingHomeId}/resident/${residentId}/update/${updateId}`)).data
    } catch (e: any) {
      throw ReqError.deleteUpdateError(e.response)
    }
  }

  static async patchUpdate({nursingHomeId, residentId, updateId, body}: IPatchUpdateRequest) {
    try {
      return (await api.put<IPatchUpdateResponse>(`nursingHome/${nursingHomeId}/resident/${residentId}/update/${updateId}`, body)).data
    } catch (e: any) {
      throw ReqError.patchUpdateError(e.response)
    }
  }

  static async getAllUpdates({nursingHomeId, searchString, priority, endDate, startDate, isRead, isAgency, employeeIds, page}: IGetAllUpdatesRequest) {
    const params = {
      employeeIds
    };

    if (!employeeIds?.length) {
      delete params.employeeIds;
    }
    try {
      const data = 
      (await api.get<IGetAllUpdatesResponse>
        (`nursingHome/${nursingHomeId}/resident/updates?searchString=${searchString || ""}&priority=${priority || ""}${isRead === null || isRead === undefined  ? "" : `&isRead=${isRead}`}${isAgency === null || isAgency === undefined  ? "" : `&isAgency=${isAgency}`}&page=${page || "0"}&endDate=${endDate || ""}&startDate=${startDate || ""}&limit=${DEFAULT_UPDATES_LIMIT}`, {params: params})).data;
      
      return {
        updates: data.updates.length ? data.updates : [],
        totalCount: data.totalCount,
      };
    } catch (e: any) {
      throw ReqError.getAllUpdatesError(e.response)
    }
  }

  static async getUpdateReaders({updateId}: IGetUpdateReadersRequest) {
    try {
      return (await api.get<IGetUpdateReadersResponse[]>(`nursingHome/resident/update/${updateId}/readers`)).data
    } catch (e: any) {
      throw ReqError.getUpdateReadersError(e.response)
    }
  }

  static async getUpdatesSentCount(req: string) {
    try {
      return (await api.get<IGetTotalUpdatesSent>(`nursingHome/${req}/updates/total-sent`)).data
    } catch (e: any) {
      throw ReqError.getUpdatesSentCountError(e.response)
    }
  }
}

export function completeUpdateResponse(update) {
  try {
    const section = getSectionNameById(update.categories.split('/')[0] as SectionsId);
    const subSection = getSubSectionNameById(update.categories.split('/')[0] as SectionsId, (update.categories.split('/')[1] || '') as SubSectionsId);
    const priority = getPriorityNameById(update.priority as UpdatePrioritiesId);
    return {...update, section, subSection, priority, phoneCall: update.phoneCall, familyPhoneCall: update.isFamilyMemberNotified, initialSchema: hydrateSchema(section, subSection, update)}
  } catch(e) {
    alert('Error in Resident service: completeUpdateResponse function')
  }

}