import ReqError from "@source/api/reqError";
import { api } from "../http";

export default class StatisticService {
  static async getUpdatesStatistic(nursingHomeId: string) {
    try {
      return (await api.get(`statistic/home/${nursingHomeId}/updates-report`, {responseType: 'arraybuffer'})).data
    } catch (e: any) {
      throw ReqError.getUpdatesStatistic(e.response)
    }
  }

  static async getNursingHomeActualStatistic(nursingHomeId: string) {
    try {
      return (await api.get(`statistic/home/${nursingHomeId}/download/actual`, {responseType: 'arraybuffer'})).data
    } catch (e: any) {
      throw ReqError.getNursingHomeActualStatisticError(e.response)
    }
  }

  static async getFamilyMembersActivationStatistic(nursingHomeId: string) {
    try {
      return (await api.get(`statistic/home/${nursingHomeId}/family-members-activation`, {responseType: 'arraybuffer'})).data
    } catch (e: any) {
      throw ReqError.getFamilyMembersActivationStatisticError(e.response)
    }
  }

  static async getEntryStatistic(entryId: string) {
    try {
      return (await api.get(`statistic/home/${entryId}/download/entry`, {responseType: 'arraybuffer'})).data
    } catch (e: any) {
      throw ReqError.getEntryStatisticError(e.response)
    }
  }

  static async getEmployeeActivationStatistic(nursingHomeIds: string[]) {
    try {
      return (await api.get(`statistic/home/download/employee-activation`, {responseType: 'arraybuffer', params: {
          nursingHomeIds
        }})).data
    } catch (e: any) {
      throw ReqError.getFamilyMembersActivationStatisticError(e.response)
    }
  }

  static async getResidentsStatistic(nursingHomeId: string) {
    try {
      return (await api.get(`statistic/home/${nursingHomeId}/residents-report`, {responseType: 'arraybuffer'})).data
    } catch (e: any) {
      throw ReqError.getFamilyMembersActivationStatisticError(e.response)
    }
  }
}
