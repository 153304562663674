import { ChangeEvent, useCallback } from "react";
import { useHistory } from "react-router-dom";
import styles from "./deactivatingResidentReasonModal.module.scss";
import Modal from "@components/UI/modal/modal";
import * as Yup from "yup";
import { useFormik } from "formik";
import Button from "@components/UI/buttons/button/button";
import RadioInput from "@components/UI/inputs/radioInput/radioInput";
import { ArchivingReason, ArchivingReasonTranslate } from "@source/api/models/allUsersModels";
import DateInput from "@source/components/UI/inputs/datePicker/datePicker";
import { IDisableResidentRequest } from "@source/api/models/residentModel";
import moment from "moment";
import ConfirmModal from "@components/confirmModal/confirmModal";

const DeactivatingResidentReasonModal = (props: DeactivatingResidentReasonModalProps) => {
  const { open, setOpen, onDeactivate } = props;
  
  const router = useHistory();
  
  const validateSchema = Yup.object({
    archivingReason: Yup.string().required("Archiving reason is required"),
  });

  const mainFormik = useFormik({
    validationSchema: validateSchema,
    validateOnChange: true,
    validateOnBlur: true, 
    initialValues: {
      archivingReason: "",
      archivedReasonDate: new Date(),
    },
    onSubmit: async (values) => {
      const deactivateReq: IDisableResidentRequest = {
        archivingReason: values.archivingReason,
        archivedReasonDate: moment(values.archivedReasonDate).toISOString()
      }

      onDeactivate(deactivateReq);
    }
  });

  const getRadios = useCallback(() => {
    let radios = [
      {value: ArchivingReason.DEATH, label: ArchivingReasonTranslate[ArchivingReason.DEATH]},
      {value: ArchivingReason.VACATION_IS_OVER, label: ArchivingReasonTranslate[ArchivingReason.VACATION_IS_OVER]},
      {value: ArchivingReason.RELOCATION, label: ArchivingReasonTranslate[ArchivingReason.RELOCATION]},
      {value: ArchivingReason.RESPITE_HOSPITAL, label: ArchivingReasonTranslate[ArchivingReason.RESPITE_HOSPITAL]},
    ];

    return radios;
  }, [])

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    mainFormik.setFieldValue("archivingReason", e.target.value);
  }

  const handleBirthDate = (date: Date | null) => {
    mainFormik.setFieldValue("archivedReasonDate", date);
  }

  const {errors, values, touched, handleBlur, handleChange, handleSubmit} = mainFormik;
  return(
    <Modal open={open} className={styles.deactivateResidentReasonModal} close={setOpen} maxWidth={484}>
      <div className={styles.deactivateResidentReason}>
        <span className={styles.title}>Reason for Deactivating Resident</span>

        <span className={`${styles.text} ${errors.archivingReason && styles.text_error}`}>
          Please select a reason for deactivation.
        </span>

        <form className={styles.form} onSubmit={handleSubmit}>
          <RadioInput
            id="archivingReason" 
            name="archivingReason_radio"
            value={values.archivingReason}
            labelPlace="end"
            className={styles.radio_input}
            onChange={handleRadioChange}
            radios={getRadios()}
            touched={touched.archivingReason}
            error={errors.archivingReason} 
          />

          <DateInput
            id="archivedReasonDate"
            label="Date occurred"
            className={styles.form_input}
            value={values.archivedReasonDate}
            placeholder="Select date"
            onChange={handleBirthDate} 
            maxDate={new Date()}
          />

          <div className={styles.confirmBtns}>
            <Button 
              className={styles.cancelBtn}
              onClick={setOpen}
              size="small"
            >
              Cancel
            </Button>

            {
              !values.archivingReason ?
                <Button
                  event="submit"
                  className={styles.form_btn} 
                >
                  Deactivate
                </Button>
                :
                <ConfirmModal
                  confirmCb={() => handleSubmit()}
                  className={styles.disableResidentModal}
                  title="Are you sure you want to deactivate the resident?"
                >
                  <Button
                    className={styles.form_btn} 
                  >
                    Deactivate
                  </Button>
                </ConfirmModal>
            }
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default DeactivatingResidentReasonModal;

interface DeactivatingResidentReasonModalProps {
  open: boolean,
  setOpen: () => void,
  onDeactivate: (disableResidentReason: IDisableResidentRequest) => void,
}

