import { memo } from "react";
import useFetching from '@source/hooks/useFetching';
import UserController from '@source/api/controllers/userController';
import { useEffect, useState } from 'react';
import userStore from '@source/store/userStore';
import LoadingPage from '@source/pages/LoadingPage/loadingPage';
import { useHistory, useLocation } from 'react-router-dom';
import AppRouter, { PrivateRoutes } from './AppRouter';
import { IGetAvailableNursingHomesRequest, IGetNursingHomesRequest } from "./api/models/nursingHomeModels";
import NursingHomeController from "./api/controllers/nursingHomeController";
import nursingHomeStore from "@store/nursingHomeStore";
import { api } from "./api/http";
import { Role } from "./utils/models/adminModels";
import { HOME_ROUTES, ROUTES, SUPER_ADMIN_PANEL_ROUTES } from "./utils/utils";
import { useAuth } from "./hooks/useAuth";
import { LOCAL_STORAGE_FROM_AGENCY_SET_PASSWORD_PAGE } from "./utils/models/userModels";

const AppInner = memo(() => {
  const router = useHistory();
  const authed = useAuth();

  const [profile, isLoading, error] = useFetching<ReturnType<typeof UserController.profile>>(UserController.profile);
  const [setTimezone, setTimezoneIsLoading,setTimezoneError] = useFetching<ReturnType<typeof UserController.setTimezone>>(UserController.setTimezone);
  const [getAvailableNursingHomes, getAvailableNursingHomesIsLoading, getAvailableNursingHomesError] = useFetching<ReturnType<typeof NursingHomeController.getAvailableNursingHomes>>(NursingHomeController.getAvailableNursingHomes);
  const location = useLocation();
  const [isNeedLoad, setIsNeedLoad] = useState(!userStore.getId() && !!PrivateRoutes.find((route) => location.pathname.includes(route.path)) && localStorage.getItem('refreshToken'));

  useEffect(() => {
    const getProfile = async () => {
      const profileInfo = await profile();

      if(profileInfo) {
        userStore.setUserInfo(profileInfo);
        
        if (profileInfo?.account.role === Role.SUPER_ADMIN) {
          if (userStore.getLocalStorageOrganizationId()) {
            userStore.setOrganizationId(userStore.getLocalStorageOrganizationId());
          } else {
            router.push(SUPER_ADMIN_PANEL_ROUTES.Organisations);
          }
        } else {
          userStore.setOrganizationId(profileInfo.account.organization._id);
        }
      }
  
      if (userStore.getLocalStorageOrganizationId() || profileInfo?.account.role !== Role.SUPER_ADMIN) {
        const getAvailableNursingHomesReq: IGetAvailableNursingHomesRequest = {
          organizationId: userStore.getOrganizationId(), 
        }
  
        const availableNursingHomesRes = await getAvailableNursingHomes(getAvailableNursingHomesReq);
  
        if (availableNursingHomesRes) {
          nursingHomeStore.setNursingHomes({
            nursingHomes: availableNursingHomesRes,
            totalCount: availableNursingHomesRes.length
          });
    
          const activeNursingHome = availableNursingHomesRes.find((userNursingHome) => userNursingHome._id === nursingHomeStore.getLocalStorageActiveNursingHome()) || availableNursingHomesRes[0];
          nursingHomeStore.setActiveNursingHome(activeNursingHome);
        
          if (nursingHomeStore.activeNursingHome) {
            api.defaults.params = {
              ...api.defaults.params,
              nursingHomeId: nursingHomeStore.activeNursingHome._id,
            }
          }
        }
  
    
        await setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      }
      
      setIsNeedLoad(false)
    }

    if(isNeedLoad) {
      getProfile();
    }

    if (!authed && location.pathname.includes(HOME_ROUTES.AgencyAccountSetPassword)) {
      localStorage.setItem(LOCAL_STORAGE_FROM_AGENCY_SET_PASSWORD_PAGE, `${location.pathname}${location.search}`);
    }

    const preferredLoginUrl = localStorage.getItem('preferredLoginPage');
    if(preferredLoginUrl) {
      router.push(preferredLoginUrl);
    }
  }, [])


  return(
    <>
    {isNeedLoad || isLoading || setTimezoneIsLoading || getAvailableNursingHomesIsLoading
      ? <LoadingPage />
      : <AppRouter />}
    </>
  )
})

export default AppInner;