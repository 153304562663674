import { memo } from "react";
import styles from "./datePicker.module.scss";
import calendarIcon from "@source/assets/imgs/calendar.svg";
import Svg from '../../svg/svg';
import { Colors } from '@source/utils/utils';
import { Application } from "react-rainbow-components";
import { DatePicker } from 'react-rainbow-components';
import iconClearDate from "@source/assets/icons/iconClearDate.svg";

/** See https://react-rainbow.io/#/DatePicker for more information */
export default memo((props: DatePickerProps) => {
  const {onChange, value, className, label, 
        disabled, placeholder, id, minDate, maxDate, required, error, touched} = props;

  const theme = {
    rainbow: {
        palette: {
          brand: Colors.primary01,
        },
    },
  };

  const onClearDate = () => {
    onChange(null);
  }

  return(
    <div className={`${styles.picker} ${className} ${disabled ? styles.picker_disabled : ''}`}>
      {label
        ? <div className={styles.picker_label}>{label}</div>
        : <></>}

      <Application theme={theme}>
        <DatePicker
          id={id}
          name={id}
          required={required}
          maxDate={maxDate}
          minDate={minDate}
          value={value || undefined}
          onChange={onChange}
          placeholder={placeholder}
          className={`
            ${styles.picker_inner} 
            ${touched && error ? styles.picker_inner_error : ''}`
          }
          icon={<Svg src={calendarIcon}/>} 
          disabled={disabled}
          formatStyle="medium"
        />

        {
          value ?
          <Svg className={`${styles.closeIcon} ${label && styles.closeIcon_label}`} width={30} height={30} src={iconClearDate} color="#333333" onClick={onClearDate}/>
          :
          <></>
        }
      </Application>

      {touched && error
        ? <div className={styles.picker_error}>{error}</div>
        : <></>}
    </div>
  )
})

interface DatePickerProps {
  /** DatePicker value */
  value: Date | null,

  /** DatePicker change handler */
  onChange: (x: Date | null) => void,

  /** className for container wrapping DateInput component */
  className?: string,

  /** DatePicker disabled 
   * @default false
  */
  disabled?: boolean,

  /** DatePicker label */
  label?: string,

  /** DatePicker placeholder */
  placeholder?: string,

  /** DatePicker id */
  id?: string,

  /** Last day user can set */
  maxDate?: Date,

  /** First day user can set */
  minDate?: Date,

  /** Is date required */
  required?: boolean,

  /** Input error message */
  error?: string,

  /** Input touched */
  touched?: boolean,
}