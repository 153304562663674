import {
  IAddFamilyMemberRequest,
  ICreateFamilyMemberRequest,
  ICreateResidentContactCommentRequest,
  ICreateResidentRequest,
  ICreateUpdateRequest,
  IDeleteResidentContactCommentRequest,
  IDeleteUpdateRequest,
  IDisableResidentRequest,
  IDownloadHomeUpdateHistoryRequest,
  IDownloadResidentUpdateHistoryRequest,
  IDownloadUnreadUpdateHistoryRequest,
  IEditFamilyMemberParticipationRequest,
  IEditFamilyMemberRelationRequest,
  IEditFamilyMemberRequest,
  IEditResidentRequest,
  IGetAllUpdatesRequest,
  IGetDisabledResidentsRequest,
  IGetResidentRequest,
  IGetResidentsRequest,
  IGetResidentUpdatesRequest,
  IGetUpdateReadersRequest,
  IGetUpdateRequest,
  IPatchUpdateRequest,
  IUpdateDeleteFileReq,
  IUpdateUploadFilesReq
} from "@source/api/models/residentModel";
import ResidentService from "@source/api/services/residentService";

export default class ResidentController {
  static async addResident(req: ICreateResidentRequest) {
    return await ResidentService.createResident(req);
  }

  static async editResident(req: IEditResidentRequest, residentId: string) {
    return await ResidentService.editResident(req, residentId);
  }

  static async deletResidentPhoto(nursingHomeId: string, residentId: string) {
    return await ResidentService.deletResidentPhoto(nursingHomeId, residentId);
  }
  
  static async disableResident(nursingHomeId: string, residentId: string, req: IDisableResidentRequest) {
    return await ResidentService.disableResident(nursingHomeId, residentId, req);
  }
  
  static async addFamilyMember(req: IAddFamilyMemberRequest, familyMemberId: string) {
    return await ResidentService.addFamilyMember(req, familyMemberId);
  }

  static async createFamilyMember(req: ICreateFamilyMemberRequest) {
    return await ResidentService.createFamilyMember(req);
  }
  
  static async editFamilyMember(req: IEditFamilyMemberRequest, familyMemberId: string) {
    return await ResidentService.editFamilyMember(req, familyMemberId);
  }

  static async editFamilyMemberParticipation(req: IEditFamilyMemberParticipationRequest, familyMemberId: string) {
    return await ResidentService.editFamilyMemberParticipation(req, familyMemberId);
  }

  static async editFamilyMemberRelation(req: IEditFamilyMemberRelationRequest, residentId: string, familyMemberId: string) {
    return await ResidentService.editFamilyMemberRelation(req, residentId, familyMemberId);
  }
  
  static async deleteFamilyMemberCommunication(familyMemberId: string, residentId: string) {
    return await ResidentService.deleteFamilyMemberCommunication(familyMemberId, residentId);
  }
  
  static async updateResidentPhoto(req: FormData, nursingHomeId: string, residentId: string) {
    return await ResidentService.updateResidentPhoto(req, nursingHomeId, residentId);
  }

  static async getResidents(req: IGetResidentsRequest) {
    return await ResidentService.getResidents(req);
  }

  static async getResidentsCount(req: string) {
    return await ResidentService.getResidentsCount(req);
  }

  static async getUpdatesSentCount(req: string) {
    return await ResidentService.getUpdatesSentCount(req)
  };

  static async getDisabledResidents(req: IGetDisabledResidentsRequest) {
    return await ResidentService.getDisabledResidents(req);
  }

  static async getDisabledResident(req: IGetResidentRequest) {
    return await ResidentService.getDisabledResident(req);
  }
  
  static async getResident(req: IGetResidentRequest) {
    return await ResidentService.getResident(req);
  }

  static async  downloadResidentUpdateHistory(nursingHomeId: string, residentId: string, req: IDownloadResidentUpdateHistoryRequest) {
    return await ResidentService.downloadResidentUpdateHistory(nursingHomeId, residentId, req);
  }

  static async downloadHomeUpdateHistory(nursingHomeId: string, req: IDownloadHomeUpdateHistoryRequest) {
    return await ResidentService.downloadHomeUpdateHistory(nursingHomeId, req);
  }

  static async  downloadUnreadUpdateHistory(nursingHomeId: string, req: IDownloadUnreadUpdateHistoryRequest) {
    return await ResidentService.downloadUnreadUpdateHistory(nursingHomeId, req);
  }

  static async createResidentContactComment(req: ICreateResidentContactCommentRequest) {
    return await ResidentService.createResidentContactComment(req);
  }

  static async deleteResidentContactComment(req: IDeleteResidentContactCommentRequest) {
    return await ResidentService.deleteResidentContactComment(req);
  }

  static async createUpdate(req: ICreateUpdateRequest) {
    return await ResidentService.createUpdate(req);
  }

  static async updateUploadFiles(req: IUpdateUploadFilesReq) {
    return await ResidentService.updateUploadFiles(req);
  }

  static async updateUploadFile(req: IUpdateUploadFilesReq) {
    return await ResidentService.updateUploadFile(req);
  }

  static async updateDeleteFile(req: IUpdateDeleteFileReq) {
    return await ResidentService.updateDeleteFile(req);
  }

  static async getResidentUpdates(req: IGetResidentUpdatesRequest) {
    return await ResidentService.getResidentUpdates(req);
  }

  static async getDisabledResidentUpdates(req: IGetResidentUpdatesRequest) {
    return await ResidentService.getDisabledResidentUpdates(req);
  }
  
  static async getUpdate(req: IGetUpdateRequest) {
    return await ResidentService.getUpdate(req);
  }

  static async deleteUpdate(req: IDeleteUpdateRequest) {
    return await ResidentService.deleteUpdate(req);
  }

  static async patchUpdate(req: IPatchUpdateRequest) {
    return await ResidentService.patchUpdate(req);
  }

  static async getAllUpdates(req: IGetAllUpdatesRequest) {
    return await ResidentService.getAllUpdates(req);
  }

  static async getUpdateReaders(req: IGetUpdateReadersRequest) {
    return await ResidentService.getUpdateReaders(req);
  }
}