import {useHistory, useParams} from "react-router-dom";
import styles from "./residentCardInfo.module.scss";
import iconEditResident from "@source/assets/icons/iconEditResident.svg";
import iconDisable from "@source/assets/icons/iconDisable.svg";
import residentsStore from "@source/store/residentsStore";
import moment from "moment";
import {observer} from "mobx-react-lite";
import {disableFieldsOrganizations, getGenderLabel, HOME_ROUTES, stringToHslColor} from "@source/utils/utils";
import userStore from "@source/store/userStore";
import Svg from "@source/components/UI/svg/svg";
import useFetching from "@source/hooks/useFetching";
import ResidentController from "@source/api/controllers/residentController";
import Notification from "@components/snackMessage/notification";
import {HomePermissionsEnum} from "@source/api/models/permissionsModels";
import {AdvanceCareDirectiveTranslate, IDisableResidentRequest} from "@source/api/models/residentModel";
import CacheImg from "@components/UI/cacheImg/cacheImg";
import nursingHomeStore from "@store/nursingHomeStore";
import {useState} from "react";
import DeactivatingResidentReasonModal
  from "@components/deactivatingResidentReasonModal/deactivatingResidentReasonModal";
import {useFirebaseLogEvent} from "@source/utils/hooks/useFirebaseLogEvent";
import {IntegrationServices} from "@source/api/models/nursingHomeModels";

const ResidentCardInfo = observer((props: ResidentCardInfoProps) => {
  const {} = props;

  const [disableResident, disableResidentLoading, disableResidentError] = useFetching<ReturnType<typeof ResidentController.disableResident>>(ResidentController.disableResident);
  const { activeResident } = residentsStore;

  const router = useHistory();
  const params = useParams<{residentId: string}>();
  const [logEvent] = useFirebaseLogEvent();

  const [deactivatingReasonModal, setDeactivatingReasonModal] = useState<boolean>(false);

  const onEditResident = () => {
    residentsStore.setEditResident(residentsStore.activeResident);
    router.push(`${HOME_ROUTES.NewResident}/${activeResident?._id}`)
  }

  const onDisableResident = async (disableResidentReason: IDisableResidentRequest) => {
    const disableResidentRes = await disableResident(activeResident?.nursingHome._id, activeResident?._id, disableResidentReason);
    
    if (disableResidentRes) {
      logEvent('admin__residents__deactivate', {
        home: nursingHomeStore.getActiveNursingHome()?.name || "",
        organization: nursingHomeStore.getActiveNursingHome()?.organization.name || "",
      })

      new Notification().success("Resident deactivated");
      router.push(`${HOME_ROUTES.Residents}`)
    }
  }

  const toggleDeactivatingReasonModal = () => {
    setDeactivatingReasonModal(!deactivatingReasonModal);
  }

  const isPartialFieldsDisabled = disableFieldsOrganizations.includes(userStore?.organizationId?.toString() || '')
  const IsLeeCareHome = nursingHomeStore.getActiveNursingHome()?.integration?.service === IntegrationServices.LEE_CARE;
  
  return (
    <div className={styles.residentCardInfo}>
      <DeactivatingResidentReasonModal open={deactivatingReasonModal} setOpen={toggleDeactivatingReasonModal} onDeactivate={onDisableResident}/>

      <div className={styles.adminActions}>
        {
          userStore.isPermission(HomePermissionsEnum.DISABLE_RESIDENT) ?
            <div className={styles.disableResident} onClick={toggleDeactivatingReasonModal}>
              <Svg
                className={styles.icon}
                src={iconDisable}
                width={24}
                height={24}
                color="#DB1818"
              />

              Deactivate
            </div>
            :
            null
        }

        {
          userStore.isPermission(HomePermissionsEnum.EDIT_RESIDENT) ?
            <div className={styles.editResident} onClick={() => onEditResident()}>
              <Svg
                className={styles.icon}
                src={iconEditResident}
                width={24}
                height={24}
                color="#3956C7"
              />

              Edit
            </div>
            :
            ""
        }
      </div>

      <div className={styles.header}>
        <CacheImg 
          url={activeResident?.photo} 
          alt="guestPhoto" 
          className={styles.photo} 
          imgClassName={styles.photo} 
          skeletonProps={{variant: 'circular'}}
        >
          <div className={styles.noPhoto} style={{backgroundColor: activeResident ? stringToHslColor(activeResident.firstName + activeResident.lastName) : ""}}>
            {activeResident?.firstName[0]?.toUpperCase() || ''} {activeResident?.lastName[0]?.toUpperCase() || ''}
          </div>
        </CacheImg>

        <div className={styles.info}>
          <div className={styles.firstRow}>
            <span className={styles.name}>{activeResident?.firstName} {activeResident?.lastName}</span>

            <span className={styles.age}>{ activeResident?.birthdate ? moment().diff(activeResident?.birthdate, 'years') + ' years' : ''}</span>
          </div>

          <span className={styles.home}>
            {activeResident?.nursingHome.name}
          </span>

          <span className={styles.place}>
            Section <span className={styles.bold}>{activeResident?.section}</span> - Room <span className={styles.bold}>{activeResident?.room}</span>
          </span>
        </div>
      </div>

      <div className={styles.infoRows}>
        <div className={styles.infoRow}>
          <span className={styles.title}>Date of birth</span>

          <span className={styles.value}>{activeResident?.birthdate ? moment(activeResident?.birthdate).format("DD MMM YYYY") : '-'}</span>
        </div>

        <div className={styles.infoRow}>
          <span className={styles.title}>Gender</span>

          <span className={`${styles.value}`}>{!isPartialFieldsDisabled ? getGenderLabel(activeResident?.gender) : '-'}</span>
        </div>

        <div className={styles.infoRow}>
          <span className={styles.title}>Admission date</span>

          <span className={styles.value}>{activeResident?.admissionDate ? moment(activeResident?.admissionDate).format("DD MMM YYYY") : '-'}</span>
        </div>

        {
          !IsLeeCareHome ?
              <div className={styles.infoRow}>
                <span className={styles.title}>Doctor</span>

                <span
                    className={`${styles.value} ${styles.value_capitalize}`}>{activeResident?.attendedDoctor?.firstName} {activeResident?.attendedDoctor?.lastName}</span>
              </div>
              :
              <></>
        }

        {
          !IsLeeCareHome ?
              <div className={styles.infoRow}>
                <span className={styles.title}>Pension / DVA</span>

                <span className={styles.value}>{activeResident?.pensionAndDVA}</span>
              </div>
              :
              <></>
        }

        <div className={styles.infoRow}>
          <span className={styles.title}>Medicare</span>

          <span className={styles.value}>{activeResident?.medicare}</span>
        </div>

        <div className={styles.infoRow}>
          <span className={styles.title}>Allergies</span>

          <div className={styles.allergies}>
            {activeResident?.allergies.map((allergy) => (
              <span className={styles.allergy} key={allergy}>{allergy}</span>
            ))}
          </div>
        </div>

        {
          !IsLeeCareHome ?
              <div className={styles.infoRow}>
                <span className={styles.title}>CPR Status</span>
                <span className={styles.value}>{AdvanceCareDirectiveTranslate[activeResident?.advanceCareDirective || ""]}</span>
              </div>
              :
              <></>
        }
      </div>
    </div>
  );
});

export default ResidentCardInfo;

interface ResidentCardInfoProps {
}
