import { IUpdateValues, Sections, SubSections, UpdatePriorities, UpdatePrioritiesId, UpdateStatus } from "@source/pages/HomePage/homeRouter/updateTab/updateSchema/updateFormModels";
import { ContactType, IGetUser } from "./allUsersModels";
import { AgencyType, EmployeeType } from "./authModels";
import { INursingHome } from "./nursingHomeModels";

export type AdvanceCareDirectiveType = "NFR" | "Resuscitate" | "to_be_determined";

export enum AdvanceCareDirective {
  NFR = 'NFR',
  RESUSCITATE = 'resuscitate',
  TBD = 'to_be_determined',
}

export const AdvanceCareDirectiveTranslate = {
  NFR: 'NFR',
  resuscitate: 'Resuscitate',
  to_be_determined: 'TBD',
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  NONE = 'none',
}

export interface ICreateFamilyMemberRequest {
  firstName: string,
  lastName: string,
  email?: string,
  relationship: string,
  address: string,
  phoneNumber: string,
  homeNumber: string,
  contactType: ContactType,
  comment: string,
  residentId: string,
  powerOfAttorney: boolean,
  enduringGuardianship: boolean
}

export interface IAddFamilyMemberRequest {
  relationship: string,
  contactType: ContactType,
  comment: string,
  residentId: string,
}

export interface IAddFamilyMemberResponse {
  firstName: string,
  lastName: string,
  email: string,
  relationship: string,
  address: string,
  phoneNumber: string,
  homeNumber: string,
  contactType: ContactType,
  comment: string,
  residentId: string,
  powerOfAttorney: boolean,
  enduringGuardianship: boolean
}

export interface IEditFamilyMemberRequest {
  firstName: string,
  lastName: string,
  phoneNumber: string,
  accountInfo: {
    homeNumber: string,
    address: string,
    enduringGuardianship: boolean
    powerOfAttorney: boolean,
  }
}

export interface IEditFamilyMemberParticipationRequest {
  nonParticipant: boolean,
  nonParticipantReason?: string
}

export interface IEditFamilyMemberRelationRequest {
  comment: string,
  contactType: string,
  relationship: string,
}

export interface IEditFamilyMemberRelationResponse {
  _id: string,
}
export enum DonwloadFileTypeDateRange {
  FULL_HISTORY = 'FULL_HISTORY',
  DATE_RANGE = 'DATE_RANGE',
}

export enum DownloadFileType {
  DOCX = 'docx',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PDF = 'pdf',
}

export const DonwloadFileTypeTranslate = {
  [DownloadFileType.DOCX]: 'Download update history (.doc)',
  [DownloadFileType.XLSX]: 'Download update history (.xlsx)',
  [DownloadFileType.PDF]: 'Download update history (.pdf)',
}

export interface IResident {
  admissionDate: string,
  advanceCareDirective: string,
  allergies: string[],
  attendedDoctor: {
    firstName: string, 
    lastName: string,
  }
  birthdate: string,
  familyRelations: IResidentFamilyRelation[],
  firstName: string,
  gender: string,
  lastName: string,
  lastUpdate: string,
  medicare: string,
  nursingHome: INursingHome,
  pensionAndDVA: string,
  photo: string,
  room: string,
  section: string,
  status: ResidentStatus,
  updatesEnabled: boolean,
  integration: ResidentIntegration
  _id: string,
}

export interface ResidentIntegration {
  hasPrimaryWithoutEmail: boolean,
  service: ResidentIntegrationService,
}

export interface IArchiveResident extends IResident {
  archivedDate?: string,
  archivingReason?: string,
  archivedReasonDate?: string
}

export enum ResidentStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum ResidentIntegrationService {
  E_CASE = 'ECase',
  LEE_CARE = 'LeeCare',
}
export interface IResidentFamilyRelation {
  comment: string,
  contactType: ContactType,
  familyMember: {
    account: {
      address: string,
      familyRelations: {_id: string}[]
      homeNumber: string,
      ios: boolean,
      isTemporaryPassword: boolean,
      organization: {_id: string}
      powerOfAttorney: boolean,
      enduringGuardianship: boolean,
      nonParticipant: boolean,
      nonParticipantReason?: string,
      _id: string,
    },
    accountType: string,
    email: string,
    firstName: string,
    invitations: string[],
    isConfirmed: boolean,
    isDisabled: boolean,
    lastName: string,
    loginDate: string,
    phoneNumber: string,
    _id: string,
  },
  relationship: string,
  resident: {_id: string}
  _id: string,
}
export interface ICreateResidentRequest {
  firstName: string,
  lastName: string,
  room: string,
  section: string,
  pensionAndDVA: string,
  medicare: string,
  admissionDate?: string,
  birthdate?: string,
  allergies: string[],
  attendedDoctor: {
    firstName: string,
    lastName: string
  },
  nursingHomeId: string,
  advanceCareDirective?: AdvanceCareDirectiveType,
  status: string,
  gender: string,
  updatesEnabled: boolean,
}

export interface IEditResidentRequest extends ICreateResidentRequest {
  gender: string,
}

export interface IDisableResidentRequest {
  archivingReason: string,
  archivedReasonDate: string,
}

export interface IDisableResidentRequest {
  archivingReason: string,
  archivedReasonDate: string,
}
export interface IDisableResidentResponse {
  message: string,
  action?: string,
}

export interface IDeleteResidentPhotoResponse {
  message: string,
}
export interface ICreateResidentResponse extends IResident {
  action?: string,
}

export interface IGetResidentsRequest {
  nursingHomeId: string,
  searchString?: string,
}

export interface IGetDisabledResidentsRequest {
  nursingHomeId: string,
  searchString?: string
}

export interface IGetResidentRequest {
  nursingHomeId: string,
  residentId: string,
}

export interface IDownloadResidentUpdateHistoryRequest {
  residentId: string,
  type: string,
  startDate?: string,
  endDate?: string,
}
export interface IDownloadHomeUpdateHistoryRequest {
  type: string,
  startDate?: string,
  endDate?: string,
}

export interface IDownloadUnreadUpdateHistoryRequest {
  type: string,
  startDate?: string,
  endDate?: string,
}

export interface ICreateResidentContactCommentRequest {
  familyMemberId: string,
  body: {
    comment: string,
    residentId: string,
  }
}

export interface IDeleteResidentContactCommentRequest {
  familyMemberId: string,
  body: {
    residentId: string,
  }
}

export interface IDeleteFamilyMemberCommunicationResponse {
  message: string,
  action?: string,
}

export interface IGetResidentsResponse {
  residents: IResident[],
  totalCount: number
}
export interface IGetResidentsCountResponse {
  inactiveCount: number,
  activeCount: number,
  totalCount: number,
}

export interface IGetResidentResponse extends IResident {
}

export interface IGetArchiveResidentResponse extends IArchiveResident {
}
export interface ICreateResidentContactResponse {
    _id: string,
    email :string,
    firstName: string,
    lastName: string,
    accountType:string,
    account: {
      _id: string,
      addres: string,
      phoneNumber:string,
      homeNumber:string,
      contactType: ContactType,
      familyRelations:
        {
          _id: string,
          relationship: string,
          comment: string,
          resident: {
            _id: string,
            firstName: string,
            lastName: string,
            room: string,
            admissionDate: string,
            allergies: string[],
            attendedDoctor: {
              firstName: string,
              lastName: string,
            },
            nursingHome: {
              _id: string,
            },
            familyRelations: {_id: string}[]
          },
          familyMember: {
            _id: string
          }
        }[]
    }
    action?: string,
}

export interface ICreateUpdateRequest {
  nursingHomeId: string,
  residentId: string,
  body: {
    multiplyScope?: string;
    timeSpent?: number;
    approximateTime: string,
    priority?: string,
    phoneCall?: string,
    additionalInfo?: IUpdateAdditionalInfo,
    status: string,
/*     isFamilyMemberNotified:boolean, */
    createdByEmployeeId: string,
    categories: string,
    chatEnable: boolean,

    view?: {
      summary?: string,
      updateCategoryName?: string,
      category: string,
      subcategory?: string
    }
  }
}

export interface IUpdateUploadFilesReq {
  nursingHomeId: string,
  residentId: string,
  updateId: string,
  body: FormData,
}

export interface IUpdateDeleteFileReq {
  nursingHomeId: string,
  residentId: string,
  updateId: string,
  fileKey: string,
}

export interface ICreateUpdateResponse {
  _id: string,
  createdDate: Date,
  approximateTime: string,
  priority: string,
  additionalInfo: IUpdateAdditionalInfo,
  chatEnable: boolean,
  status: string,
  categories: string,
/*   isFamilyMemberNotified:boolean, */

  view: {
    summary?: string,
    updateCategoryName?: string,
    category: string,
    subcategory?: string
  }

  resident: {
    _id: string,
    firstName: string,
    lastName: string,
    room: string,
    pension: string,
    DVA: string,
    admissionDate: Date,
    allergies: string,
  
    nursingHome: {
      name: string,
      address: string,
      _id: string,
    }
  
    attendedDoctor: {
      firstName: string,
      lastName: string,
    },
  
    familyMembers: {
      _id: string,
      email: string,
      firstName: string,
      lastName: string,
      accountType: string
    }
  }
  action?: string,
}



export interface IGetResidentUpdatesRequest {
  nursingHomeId: string,
  residentId: string,
  page?: number,
}
export interface IGetResidentUpdatesResponse {
  updates: IGetUpdateResponse[],
  totalCount: number,
}

export interface IGetUpdateRequest {
  nursingHomeId: string,
  residentId: string,
  updateId: string,
}

export interface IDeleteUpdateRequest {
  nursingHomeId: string,
  residentId: string,
  updateId: string,
}

export interface IPatchUpdateRequest {
  nursingHomeId: string,
  residentId: string,
  updateId: string,
  body: {
    approximateTime: string,
    priority?: string,
    phoneCall?: string,
    chatEnable?: boolean,
    status: string,
/*     isFamilyMemberNotified:boolean, */
    additionalInfo?: IUpdateAdditionalInfo,
    categories: string,

    view?: {
      summary?: string,
      category: string,
      updateCategoryName?: string,
      subcategory?: string
    }
  }
}

export interface IPatchUpdateResponse {

}

export interface IGetAllUpdatesRequest {
  nursingHomeId: string,
  searchString?: string,
  priority?: string,
  startDate?: string,
  endDate?: string,
  page?: number,
  isRead?: boolean | null,
  isAgency?: boolean | null,
  employeeIds?: string[],
}

export interface IGetUpdateReadersRequest {
  updateId: string,
}

export interface IGetUpdateReadersResponse extends IUpdateReader {
  
}

export interface IGetTotalUpdatesSent {
  totalSent: number;
}

export interface IUpdate {
  additionalInfo?: IUpdateAdditionalInfo,
  approximateTime: string;
  categories: string;
  chatEnable: boolean;
  createdByEmployee: {
    account: {
      nursingHomes: { _id: string }[];
      organization: { _id: string };
      permissions: {
        global: null;
        homes: {
          home: {
            address: string;
            email: string;
            name: string;
            organization: { _id: string };
            phone: string;
            photo: string;
            _id: string;
          };
          permissions: string[];
        }[];
        organization: {
          organization: {
            description: string;
            name: string;
            owner: {
              email: string;
              firstName: string;
              lastName: string;
              phone: string;
            };
          };
          permissions: string[];
        };
      };
      position: string;
      role: string;
      stuffId: string;
      _id: string;
    };
    accountType: string;
    email: string;
    firstName: string;
    isConfirmed: boolean;
    isDisabled: boolean;
    lastName: string;
    loginDate: string;
    photo: string;
    _id: string;
  };

  createdDate: string;
  draftDate?: string,
  files?: IUpdateFile[],
  familyPhoneCall: boolean;
  initialSchema: any;
/*   isFamilyMemberNotified: boolean; */
  isFamilyMemberRead: boolean;
  priority: UpdatePriorities | undefined;

  resident: {
    admissionDate: string;
    advanceCareDirective: string;
    allergies: string[],
    attendedDoctor: {
        firstName: string; 
        lastName: string;
    }
    birthdate:string;
    familyRelations: {_id: string}[]
    firstName: string;
    gender: string;
    lastName: string;
    lastUpdate: string;
    medicare: string;
    nursingHome: {_id: string;}
    pensionAndDVA: string;
    photo: string;
    room: string;
    section: string;
    status: string;
    _id: string;
  };

  section: Sections | undefined;
  status: UpdateStatus | undefined;
  subSection: SubSections | undefined;

  submittedBy: {
    account: {
        nursingHomes: { _id: string }[];
        organization: { _id: string };
        permissions: {
          global: null;
          homes: {
            home: {
              address: string;
              email: string;
              name: string;
              organization: { _id: string };
              phone: string;
              photo: string;
              _id: string;
            };
            permissions: string[];
          }[];
          organization: {
            organization: {
              description: string;
              name: string;
              owner: {
                email: string;
                firstName: string;
                lastName: string;
                phone: string;
              };
            };
            permissions: string[];
          };
        };
        position: string;
        role: string;
        stuffId: string;
        _id: string;
      };
      accountType: string;
      email: string;
      firstName: string;
      isConfirmed: boolean;
      isDisabled: boolean;
      lastName: string;
      loginDate: string;
      photo: string;
      _id: string;
  };

  updateReaders: IUpdateReader[]

  view: {
    category: string;
    subcategory: string;
    summary: string;
    updateCategoryName: string;
  }

  _id: string;
}

export interface IUpdateFile {
  file: string;
  key: string;
  name: string;
  size: string;
}

export interface IUpdateReader { 
  contactType: ContactType,
  lastRead: string,
  reader: IGetUser,
  reading: string[],
  update: {
    _id: string,
  },
  _id: string,
}

export interface IGetReadingData {
  id: string;
  updateReaders: IUpdateReader[];
}

export interface IGetUpdatesDataResponse {
  updates: IGetUpdateResponse[];
  totalCount: number;
}
export interface IGetUpdateResponse {
  additionalInfo?: IUpdateAdditionalInfo,
  approximateTime: string;
  categories: string;
  chatEnable: boolean;
  onlyForMember?: IGetUser;
  onlyForMemberContactType?: ContactType;
  createdByEmployee: {
    account: {
      agencyInfo?: {
        registrationNumber: string,
        rootId: string,
        type: AgencyType,
      },
      employeeType: EmployeeType,
      nursingHomes: { _id: string }[];
      organization: { _id: string };
      permissions: {
        global: null;
        homes: {
          home: {
            address: string;
            email: string;
            name: string;
            organization: { _id: string };
            phone: string;
            photo: string;
            _id: string;
          };
          permissions: string[];
        }[];
        organization: {
          organization: {
            description: string;
            name: string;
            owner: {
              email: string;
              firstName: string;
              lastName: string;
              phone: string;
            };
          };
          permissions: string[];
        };
      };
      position: string;
      role: string;
      stuffId: string;
      _id: string;
    };
    accountType: string;
    email: string;
    firstName: string;
    isConfirmed: boolean;
    isDisabled: boolean;
    lastName: string;
    loginDate: string;
    photo: string;
    _id: string;
  };

  createdDate: string;
  draftDate?: string,
  files?: IUpdateFile[],
  familyPhoneCall: boolean;
  initialSchema: any;
/*   isFamilyMemberNotified: boolean; */
  isFamilyMemberRead: boolean;
  priority: UpdatePrioritiesId | undefined;

  resident: {
    admissionDate: string;
    advanceCareDirective: string;
    allergies: string[],
    attendedDoctor: {
        firstName: string; 
        lastName: string;
    }
    birthdate:string;
    familyRelations: {_id: string}[]
    firstName: string;
    gender: string;
    lastName: string;
    lastUpdate: string;
    medicare: string;
    nursingHome: {_id: string;}
    pensionAndDVA: string;
    photo: string;
    room: string;
    section: string;
    status: string;
    _id: string;
  };

  status: UpdateStatus | undefined;

  submittedBy: {
    account: {
        nursingHomes: { _id: string }[];
        organization: { _id: string };
        permissions: {
          global: null;
          homes: {
            home: {
              address: string;
              email: string;
              name: string;
              organization: { _id: string };
              phone: string;
              photo: string;
              _id: string;
            };
            permissions: string[];
          }[];
          organization: {
            organization: {
              description: string;
              name: string;
              owner: {
                email: string;
                firstName: string;
                lastName: string;
                phone: string;
              };
            };
            permissions: string[];
          };
        };
        position: string;
        role: string;
        stuffId: string;
        _id: string;
      };
      accountType: string;
      email: string;
      firstName: string;
      isConfirmed: boolean;
      isDisabled: boolean;
      lastName: string;
      loginDate: string;
      photo: string;
      _id: string;
  };

  updateReaders: IUpdateReader[]

  view: {
    category: string;
    subcategory: string;
    summary: string;
    updateCategoryName: string;
  }

  _id: string;
}
export interface IGetAllUpdatesResponse {
  updates: IGetUpdateResponse[],
  totalCount: number,
}

export interface IGetChatsRequest {
  nursingHomeId: string,
  searchString?: string,
}

export interface IGetChatRequest {
  chatId: string,
}

export interface ICreateChatRequest {
  updateId: string,
}

export interface ICloseChatRequest {
 chatId: string,
}

export interface IReopenChatRequest {
  chatId: string,
 }

export interface INewMessageChatRequest {
  chatId: string,
  body: {
    text: string,
    images?: string[],
  }
}

export interface IUpdateAdditionalInfo {
  inputValues?: IUpdateValues,
}