import { memo } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Logo from "@source/components/UI/logo/logo";
import styles from "./sideBar.module.scss";
import iconResidents from "@source/assets/icons/iconResidents.svg";
import iconHistory from "@source/assets/icons/iconHistory.svg";
import iconAnnouncements from "@source/assets/icons/iconAnnouncements.svg";
import iconEditResident from "@source/assets/icons/iconEditResident.svg";
import iconArchive from "@source/assets/icons/iconArchive.svg";
import iconHelp from "@source/assets/icons/iconHelp.svg";
import iconXslxFile from "@source/assets/icons/iconXlsxFile.svg";
import { HOME_ROUTES, LogoType, ROUTES } from "@source/utils/utils";
import openMenuBack from '@source/assets/icons/openMenuBack.svg';
import openMenuForward from '@source/assets/icons/openMenuForward.svg';
import Svg from "@source/components/UI/svg/svg";
import userStore from "@source/store/userStore";
import { observer } from "mobx-react-lite";
import Loader from "@source/components/UI/loader/loader";
import { HomePermissionsEnum, OrganizationPermissionsEnum } from "@source/api/models/permissionsModels";
import CacheImg from "@components/UI/cacheImg/cacheImg";

const SideBar = observer((props: SideBarProps) => {
  const {open, toggleSideBar} = props;
  const router = useHistory();

  const navigateToSettings = () => {
    router.push(HOME_ROUTES.Settings)
  }

  const isShowAdminPanelLink = () => {
    return userStore.isPermission(OrganizationPermissionsEnum.EDIT_EMPLOYEE_PASSWORD) || 
    // userStore.isPermission(OrganizationPermissionsEnum.EDIT_EMPLOYEE_PERMISSION) ||
    userStore.isPermission(OrganizationPermissionsEnum.ENABLE_EMPLOYEE) ||
    userStore.isPermission(OrganizationPermissionsEnum.ADD_NEW_EMPLOYEE) ||
    userStore.isPermission(OrganizationPermissionsEnum.DISABLE_EMPLOYEE) ||
    userStore.isPermission(OrganizationPermissionsEnum.DISABLE_FAMILY_MEMBER) ||
    userStore.isPermission(OrganizationPermissionsEnum.ENABLE_FAMILY_MEMBER) ||
    userStore.isPermission(OrganizationPermissionsEnum.CREATE_ACCESS_KEYS) ||
    userStore.isPermission(OrganizationPermissionsEnum.UPDATE_ACCESS_KEYS) ||
    userStore.isPermission(OrganizationPermissionsEnum.VIEW_ACCESS_KEYS) ||
    userStore.isPermission(OrganizationPermissionsEnum.ADD_NEW_HOUSE)
  }

  const getAdminLinkRoute = () => {
    if (
      userStore.isPermission(OrganizationPermissionsEnum.EDIT_EMPLOYEE_PASSWORD) || 
      // userStore.isPermission(OrganizationPermissionsEnum.EDIT_EMPLOYEE_PERMISSION) ||
      userStore.isPermission(OrganizationPermissionsEnum.ENABLE_EMPLOYEE) ||
      userStore.isPermission(OrganizationPermissionsEnum.ADD_NEW_EMPLOYEE) ||
      userStore.isPermission(OrganizationPermissionsEnum.DISABLE_EMPLOYEE)
    ) {
      return HOME_ROUTES.AdminPanelStaff;
    } 

    if (
      userStore.isPermission(OrganizationPermissionsEnum.DISABLE_FAMILY_MEMBER) ||
      userStore.isPermission(OrganizationPermissionsEnum.ENABLE_FAMILY_MEMBER)
    ) {
      return HOME_ROUTES.AdminPanelFamilyMembers;
    } 

    if (
      userStore.isPermission(OrganizationPermissionsEnum.CREATE_ACCESS_KEYS) ||
      userStore.isPermission(OrganizationPermissionsEnum.UPDATE_ACCESS_KEYS) ||
      userStore.isPermission(OrganizationPermissionsEnum.VIEW_ACCESS_KEYS) ||
      userStore.isPermission(OrganizationPermissionsEnum.ADD_NEW_HOUSE)
    ) {
      return HOME_ROUTES.AdminPanelHouses;
    } 

    return "";
  }

  return(
    <div className={`${styles.sideBar}`}>
      <Logo className={styles.logo} type={open ? LogoType.Blue : LogoType.Short}/>

      <div className={styles.toggle}>
        <img className={styles.toggle__btn} 
            src={open ? openMenuBack : openMenuForward} 
            alt="toggleMenu" onClick={toggleSideBar}/>

        {open && <span className={styles.toggle__text}>Hide menu</span>}
      </div>

      <div className={`${styles.nav}`} >
        {
          userStore.isPermission(HomePermissionsEnum.VIEW_RESIDENTS) ?
            <NavLink to={HOME_ROUTES.Residents} className={styles.nav__item} 
                  activeClassName={styles.nav__item_active}>
                <div className={styles.iconWrapper}>
                  <Svg className={styles.icon} src={iconResidents}/>

                  {
                    false ?
                    <div className={styles.newEvent}/>
                    :
                    <></>
                  }
                </div>

                {open && <span className={styles.text}>Residents</span>}
            </NavLink>
            :
            <></>
        }

        {
          userStore.isPermission(HomePermissionsEnum.VIEW_UPDATES) ?
          <NavLink to={HOME_ROUTES.History} className={styles.nav__item} 
                activeClassName={styles.nav__item_active}>
              <div className={styles.iconWrapper}>
                <Svg className={styles.icon} src={iconHistory}/>

                {
                  false ?
                  <div className={styles.newEvent}/>
                  :
                  <></>
                }
              </div>

              {open && <span className={styles.text}>Updates history</span>}
          </NavLink>
          :
          <></>
        }
        
        {/* <NavLink to={HOME_ROUTES.Chat} className={styles.nav__item} 
              activeClassName={styles.nav__item_active}>
            <div className={styles.iconWrapper}>
              <Svg className={styles.icon} src={iconChat}/>

              {
                chatsStore.isHaveUnreadMessages ?
                <div className={styles.newEvent}/>
                :
                <></>
              }
            </div>

            {open && <span className={styles.text}>Chat</span>}
        </NavLink> */}
        {
          userStore.isPermission(HomePermissionsEnum.VIEW_ANNOUNCEMENT_EVENT) ?
            <NavLink to={HOME_ROUTES.Announcements} className={styles.nav__item} 
                  activeClassName={styles.nav__item_active}>
                <div className={styles.iconWrapper}>
                  <Svg className={styles.icon} src={iconAnnouncements}/>
                </div>

                {open && <span className={styles.text}>Announcements</span>}
            </NavLink>
            :
            <></>
        }

        {
          isShowAdminPanelLink() ?
          <NavLink to={getAdminLinkRoute()} exact={false} className={styles.nav__item} 
                activeClassName={styles.nav__item_active}>
            <div className={styles.iconWrapper}>
              <Svg className={styles.icon} src={iconEditResident}/>
            </div>

            {open && <span className={styles.text}>Admin panel</span>}
          </NavLink>
          :
          <></>
        } 

        {
          userStore.isPermission(HomePermissionsEnum.VIEW_DISABLED_RESIDENT) ?
          <NavLink to={HOME_ROUTES.ResidentsArchive} exact={false} className={styles.nav__item} 
                activeClassName={styles.nav__item_active}>
            <div className={styles.iconWrapper}>
              <Svg className={styles.icon} src={iconArchive}/>
            </div>

            {open && <span className={styles.text}>Archive</span>}
          </NavLink>
          :
          <></>
        }

        {/*
          userStore.isPermission(HomePermissionsEnum.VIEW_HOME_STATISTIC) &&
            <NavLink to={HOME_ROUTES.Exports} exact={false} className={styles.nav__item}
                     activeClassName={styles.nav__item_active}>
              <div className={styles.iconWrapper}>
                <Svg className={styles.icon} src={iconXslxFile}/>
              </div>

              {open && <span className={styles.text}>Exports</span>}
            </NavLink>
        */}

        <NavLink to={HOME_ROUTES.Help} exact={false} className={styles.nav__item} 
              activeClassName={styles.nav__item_active}>
          <div className={styles.iconWrapper}>
            <Svg className={styles.icon} src={iconHelp}/>
          </div>

          {open && <span className={styles.text}>Help</span>}
        </NavLink>
      </div>

      <div className={styles.footer}>
        <div className={styles.line}/>

        {
          userStore.getId() ?
            <div className={styles.user} onClick={navigateToSettings}>
                <CacheImg
                  url={userStore.getUserInfo()?.photo} 
                  alt="userPhoto" 
                  className={styles.user__photo} 
                  imgClassName={styles.user__photo} 
                >
                  <div className={styles.user__avatar}>
                    {userStore.getUserInfo()?.firstName[0] || "" + userStore.getUserInfo()?.lastName[0] || ""}
                  </div>
                </CacheImg>
                
                {open 
                  ? <div className={styles.user__info}>
                      <span className={styles.name}>
                        <span>{userStore.getUserInfo()?.firstName} {userStore.getUserInfo()?.lastName}</span> 
                      </span>

                      <span className={styles.role}>{userStore.getUserInfo()?.accountType}</span>
                    </div>
                  : <></>
                }
            </div>
            :
            <Loader className={styles.loader} height={25} width={25} borderWidth={3}/>
        }
        </div>
    </div>
  )
})

export default SideBar;

interface SideBarProps {
  open: boolean;
  toggleSideBar: () => void;
}