import { ContactTypeEnum, IGetAllUsersResponse, IGetUser } from "@source/api/models/allUsersModels";
import { IArchiveResident, IGetReadingData, IGetUpdatesDataResponse, IGetResidentsCountResponse, IGetResidentsResponse, IGetUpdateResponse, IResident, IResidentFamilyRelation, IUpdate } from "@source/api/models/residentModel";
import { makeAutoObservable, toJS } from "mobx";

class ResidentsStore {
  multipleUpdateSelectedResidents: IResident[] | null = null;
  residents: IResident[] = [];
  residentsTotalCount: number | null = null;
  residentsCount: IGetResidentsCountResponse | null = null;
  disabledResidents: IResident[] = [];
  activeResident: IResident | null = null;
  focusOnResident: IResident | null = null;
  activeArchiveResident: IArchiveResident | null = null;
  editResident: IResident | null = null;
  addResidentFamilyMembers: IResident | null = null;
  editFamilyMember: IResidentFamilyRelation | null = null;
  activeResidentUpdates: IGetUpdateResponse[] = [];
  activeResidentUpdatesTotalCount: number = 0;
  activeArchiveResidentUpdatesTotalCount: number = 0;
  activeArchiveResidentUpdates: IGetUpdateResponse[] = [];
  allFamilyMembers: IGetUser[] | null = null;
  modalBlockNewUpdatesResident: IResident | null = null;
  modalNewUpdatesDisabledResident: IResident | null = null;
  updateTimeStart: Date | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setMultipleUpdateSelectedResidents(selectedResidents: IResident[] | null) {
    this.multipleUpdateSelectedResidents = selectedResidents;
  }

  getMultipleUpdateSelectedResidents() {
    return this.multipleUpdateSelectedResidents;
  }

  setFocusOnResident(resident: IResident | null) {
    this.focusOnResident = resident;
  }

  getFocusOnResident() {
    return this.focusOnResident;
  }

  setModalBlockNewUpdatesResident(resident: IResident | null) {
    this.modalBlockNewUpdatesResident = resident;
  }

  getModalBlockNewUpdatesResident() {
    return this.modalBlockNewUpdatesResident;
  }

  setModalNewUpdatesDisabledResident(resident: IResident | null) {
    this.modalNewUpdatesDisabledResident = resident;
  }

  getModalNewUpdatesDisabledResident() {
    return this.modalNewUpdatesDisabledResident;
  }

  setResidents(residentsRes: IGetResidentsResponse) {
    this.residents = residentsRes.residents;
    this.residentsTotalCount = residentsRes.totalCount;
  }

  setResidentsCount(residentsCount: IGetResidentsCountResponse) {
    this.residentsCount = residentsCount;
  }

  getResidentsCount() {
    return this.residentsCount;
  }

  setDisabledResidents(residents: IResident[]) {
    this.disabledResidents = residents;
  }

  getDisabledResidents() {
    return this.disabledResidents;
  }

  setAllFamilyMembers(familyMembers: IGetAllUsersResponse) {
    this.allFamilyMembers = familyMembers.users;
  }
  
  getAllFamilyMembers() {
    return this.allFamilyMembers || [];
  }

  setAddResidentFamilyMembers(resident: IResident | null) {
    this.addResidentFamilyMembers = resident;
  }

  getAddResidentFamilyMembers() {
    return this.addResidentFamilyMembers;
  }

  setEditFamilyMember(familyMember: IResidentFamilyRelation | null) {
    this.editFamilyMember = familyMember;
  }
  
  setEditResident(resident: IResident | null) {
    this.editResident = resident;
  }

  getResidents() {
    return this.residents;
  }

  getResidentsTotalCount() {
    return this.residentsTotalCount;
  }
  
  setActiveResident(resident: IResident | null) {
    this.activeResident = resident;
  }

  getActiveResident() {
    return this.activeResident;
  }

  getActiveResidentUpdateEnabled() {
    return !!this.activeResident && this.activeResident.updatesEnabled;
  }

  getActiveResidentFamilyMemberChoseNotToUseApp() {
    const foundedPrimaryContact = this.activeResident?.familyRelations.find((familyRelations) => familyRelations.contactType === ContactTypeEnum.primary);

    return !!this.activeResident && foundedPrimaryContact && foundedPrimaryContact.familyMember.loginDate && foundedPrimaryContact.familyMember.account.isTemporaryPassword;
  }

  isActiveResidentPrimaryContactActivated(): boolean {
    return !!this.activeResident && 
      this.activeResident?.familyRelations.some((familyRelation) => familyRelation.contactType === ContactTypeEnum.primary && !familyRelation.familyMember.account.isTemporaryPassword && familyRelation.familyMember.isConfirmed);
  }

  setActiveArchiveResident(resident: IArchiveResident | null) {
    this.activeArchiveResident = resident;
  }

  getActiveArchiveResident() {
    return this.activeArchiveResident;
  }

  setActiveResidentUpdates(updates: IGetUpdateResponse[]) {
    this.activeResidentUpdates = updates;
  }

  markResidentUpdateRead(data: IGetReadingData) {
    this.setActiveResidentUpdates(this.activeResidentUpdates.map(update => {
      if (update._id === data.id) {
        return { ...update, isFamilyMemberRead: true, updateReaders: data.updateReaders };
      }
      return update;
    }))
  }
  setActiveResidentData(data: IGetUpdatesDataResponse) {
    this.activeResidentUpdates = data.updates;
    this.activeResidentUpdatesTotalCount = data.totalCount;
  }

  addActiveResidentData(data: IGetUpdatesDataResponse) {
    this.activeResidentUpdates = this.activeResidentUpdates.concat(data.updates);
    this.activeResidentUpdatesTotalCount = data.totalCount;
  }

  setActiveArchiveResidentUpdates(updates: IGetUpdateResponse[]) {
    this.activeArchiveResidentUpdates = updates;
  }

  markArchiveResidentUpdateRead(data: IGetReadingData) {
    this.setActiveArchiveResidentUpdates(this.activeArchiveResidentUpdates.map(update => {
      if (update._id === data.id) {
        return { ...update, isFamilyMemberRead: true, updateReaders: data.updateReaders };
      }
      return update;
    }))
  }
  setActiveArchiveResidentData(data: IGetUpdatesDataResponse) {
    this.activeArchiveResidentUpdates = data.updates;
    this.activeArchiveResidentUpdatesTotalCount = data.totalCount;
  }

  addActiveArchiveResidentData(data: IGetUpdatesDataResponse) {
    this.activeArchiveResidentUpdates = this.activeArchiveResidentUpdates.concat(data.updates);
    this.activeArchiveResidentUpdatesTotalCount = data.totalCount;
  }

  getActiveArchiveResidentUpdates() {
    return this.activeArchiveResidentUpdates;
  }

  deleteActiveResidentUpdates(updateId: string) {
    this.activeResidentUpdates = this.activeResidentUpdates.filter(update => update._id !== updateId);
    this.activeResidentUpdatesTotalCount = this.activeResidentUpdatesTotalCount - 1;
  }

  deleteActiveArchiveResidentUpdates(updateId: string) {
    this.activeArchiveResidentUpdates = this.activeArchiveResidentUpdates.filter(update => update._id !== updateId);
    this.activeArchiveResidentUpdatesTotalCount = this.activeArchiveResidentUpdatesTotalCount - 1;
  }

  deleteActiveResidentContactComment(familyMemberId: string) {
    const foundedIndex = this.activeResident?.familyRelations.findIndex(familyRelation => familyRelation.familyMember._id === familyMemberId);

    if (foundedIndex !== undefined && this.activeResident) {
      this.activeResident.familyRelations[foundedIndex].comment = "";
    }
  }

  addActiveResidentContactComment(familyMember: string, comment: string) {
    const foundedIndex = this.activeResident?.familyRelations.findIndex(familyRelation => familyRelation.familyMember._id === familyMember);

    if (foundedIndex !== undefined && this.activeResident) {
      this.activeResident.familyRelations[foundedIndex].comment = comment;
    }
  }

  disableActiveResidentsFamilyMembers(familyMemberId: string) {
    if (this.activeResident) {
      this.activeResident.familyRelations = this.activeResident.familyRelations.filter(familyRelation => familyRelation.familyMember._id !== familyMemberId);
    }
  }
}

export default new ResidentsStore();
