import { INursingHome, IOrganization } from "./nursingHomeModels";

export interface IGetAllUsersResponse {
  totalCount: number,
  users: IGetUser[],
}

export interface IGetAllFamilyMembersResponse {
  totalCount: number,
  users: IGetUser[],
}

export interface IGetUser {
  _id: string,
  email: string,
  phoneNumber: string,
  firstName: string,
  lastName: string,
  photo: string,
  isDisabled: boolean,
  isConfirmed: boolean,
  loginDate: string | null,
  activationDate?: string,
  accountType: AccountType,
  account: {
    _id: string,
    address: string,
    phoneNumber: string,
    homeNumber: string,
    powerOfAttorney: boolean,
    enduringGuardianship: boolean,
    nonParticipant: boolean,
    nonParticipantReason?: string,
    isTemporaryPassword: boolean,
    organization: IOrganization,
    familyRelations: {
        _id: string,
        relationship: string,
        contactType: ContactType,
        resident: {
          _id: string,
          firstName: string,
          lastName: string,
          photo: string | null,
          room: string,
          section: string,
          admissionDate: string,
          lastUpdate: string,
          allergies: string[],
          attendedDoctor: {
            _id: string,
          },
          nursingHome: {
            address: string,
            email: string,
            name: string,
            organization: {_id: string,}
            phone: string,
            photo: string,
            _id: string,
          },
          chat: {
            _id: string,
          },
          familyRelations: {
              _id: string,
          }[],
          status: StatusType,
        },
        familyMember: {
          _id: string,
        }
    }[]
  }
}
 
export interface IGetAllUsersReq {
  searchString: string,
  accountType: AccountType,
  limit: number,
  page: number,
  isDisabled: string,
}

export interface IGetAllFamilyMembersReq {
  searchString: string,
  organizationId: string,
  limit: number,
  page: number,
  isDisabled: string,
  nursingHomeIds: string[],
  // linked: string,
}

export type AccountType = 'FamilyMember' | 'Emlpoyee';

export type ContactType = 'primary' | 'secondary' | 'other';

export enum ContactTypeEnum {
  primary = "primary",
  secondary = "secondary",
  other = "other",
}

export enum ArchivingReason {
  OTHER = 'other',
  DEATH = 'death',
  VACATION_IS_OVER = 'vacation_is_over',
  RELOCATION = 'relocation',
  RESPITE_HOSPITAL = 'respite_hospital'
}

export const ArchivingReasonTranslate = {
  [ArchivingReason.DEATH]: "Resident passed away",
  [ArchivingReason.VACATION_IS_OVER]: "End of Respite Care Period",
  [ArchivingReason.RELOCATION]: "Moving from nursing home",
  [ArchivingReason.RESPITE_HOSPITAL]: "Respite stay - transfer to hospital",
}

export type StatusType = 'active' | 'inactive';

export interface FamilyMemberTableData {
  name: string,
  firstName: string,
  lastName: string,
  email: string,
  photo: string,
  accountType: AccountType,
  _id: string,
  isDisabled: boolean,
  address: string,
  phoneNumber: string,
  homeNumber: string,
  isConfirmed: boolean,
  loginDate: string,
  activationDate?: string;
  isTemporaryPassword: boolean,
  actions: null,
  nursingHomes: string[],
  residents: FamilyMemberTableDataResident[]
}

export interface FamilyMemberTableDataResident {
  firstName: string,
  lastName: string,
  room: string,
  section: string,
  photo: string,
  nursingHomeId: string,
  _id: string,
  status: string,
}

export interface HeadCellFamilyMember {
  disablePadding: boolean;
  id: keyof FamilyMemberTableData;
  label: string;
}

export type Order = 'asc' | 'desc';