import { useHistory } from "react-router-dom";
import styles from "./residentCard.module.scss";
import iconConfirmed from '@source/assets/icons/iconConfirmed.svg';
import iconNoPrimaryContact from '@source/assets/icons/noPrimaryContact.svg';
import iconResidentSelectableCheck from '@source/assets/icons/iconResidentSelectableCheck.svg';
import { GridVariant } from "@source/pages/HomePage/homeRouter/residentsTab/residentsTab";
import { HOME_ROUTES, stringToHslColor } from "@source/utils/utils";
import moment from "moment";
import residentStore from "@source/store/residentsStore";
import { observer } from "mobx-react-lite";
import { ContactTypeEnum } from "@source/api/models/allUsersModels";
import CacheImg from "@components/UI/cacheImg/cacheImg";
import { IResident, ResidentStatus } from "@source/api/models/residentModel";
import residentsStore from "@source/store/residentsStore";
import { useEffect, useRef } from "react";
import nursingHomeStore from "@store/nursingHomeStore";
import { useFirebaseLogEvent } from "@source/utils/hooks/useFirebaseLogEvent";

const ResidentCard = observer((props: ResidentCardProps) => {
  const { gridVariant, resident } = props;
  const router = useHistory();
  const [logEvent] = useFirebaseLogEvent();

  const focusRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if(residentStore.getFocusOnResident()) {
      if (focusRef.current) {
        focusRef.current.scrollIntoView({block: 'center', inline: 'center', behavior: 'smooth'});
      }
    }
  }, [residentStore.focusOnResident, focusRef])

  const navigateResident = () => {
    
    if (resident.status === ResidentStatus.ACTIVE) {
      residentStore.setActiveResident(resident);
      router.push(`${HOME_ROUTES.Residents}/${resident._id}`)
    } else {
      residentStore.setActiveArchiveResident(resident);
      router.push(`${HOME_ROUTES.ResidentsArchive}/${resident._id}`)
    }
  }

  const isSelectableResident = () => {
    return !!residentStore.getMultipleUpdateSelectedResidents();
  }

  const isSelectedResident = () => {
    return !!residentStore.getMultipleUpdateSelectedResidents()?.find((selectedResident) => selectedResident._id === resident._id)
  }

  const onNewUpdate = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (isSelectableResident()) {
      return;
    }

    e.stopPropagation();

    if (!isResidentUpdatesEnabled()) {
      residentsStore.setModalNewUpdatesDisabledResident(resident);
      return;
    }

    if (!isPrimaryContactConfirmed()) {
      residentStore.setModalBlockNewUpdatesResident(resident);
      return;
    }

    if (isPrimaryContactConfirmed()) {
      logEvent('updates__click_new_update_button', {
        home: nursingHomeStore.getActiveNursingHome()?.name || "",
        organization: nursingHomeStore.getActiveNursingHome()?.organization.name || "",
      })

      router.push({
        pathname: `${HOME_ROUTES.Residents}/${resident._id}/update`,
      });
      return;
    }
  }

  const isPrimaryContactConfirmed = () => {
    return resident.familyRelations.some((familyRelation) => 
    familyRelation.contactType === ContactTypeEnum.primary && 
    !familyRelation?.familyMember?.account?.isTemporaryPassword &&
    !familyRelation?.familyMember?.account?.nonParticipant &&
    familyRelation?.familyMember?.isConfirmed &&
    !familyRelation?.familyMember?.isDisabled &&
    resident.updatesEnabled
    )
  }

  const isHasPrimary = () => {
    return resident.familyRelations.some((familyRelation) =>
        familyRelation.contactType === ContactTypeEnum.primary && !familyRelation?.familyMember?.isDisabled
    )
  }

  const isResidentUpdatesEnabled = () => {
    return !!resident && resident.updatesEnabled;
  }

  const getSelectableClassName = () => {
    const multipleUpdateSelectedResidents = residentStore.getMultipleUpdateSelectedResidents();

    return multipleUpdateSelectedResidents && isPrimaryContactConfirmed() && resident.status === ResidentStatus.ACTIVE  ? 
        multipleUpdateSelectedResidents.find((selectedResident) => selectedResident._id === resident._id) ?
          styles.residentCard_selected
          :
          styles.residentCard_selectable
      : '';
  }

  const onSelectResident = () => {
    const multipleUpdateSelectedResidents = residentStore.getMultipleUpdateSelectedResidents();

    if (multipleUpdateSelectedResidents  && isPrimaryContactConfirmed() && resident.status === ResidentStatus.ACTIVE) {
      isSelectedResident() ?
        residentStore.setMultipleUpdateSelectedResidents(multipleUpdateSelectedResidents.filter((selectedResident) => selectedResident._id !== resident._id))
        :
        residentStore.setMultipleUpdateSelectedResidents([...multipleUpdateSelectedResidents, resident]);
    }
  }

  const onCardClick = () => {
    isSelectableResident() ? 
      onSelectResident()
      :
      navigateResident();
  }


  return(
    <div ref={residentStore.getFocusOnResident()?._id === resident._id ? focusRef : null} id={resident._id} 
      className={`${styles.residentCard} ${gridVariant === GridVariant.Lines && styles.residentCard_lines} ${getSelectableClassName()}`} 
         onClick={onCardClick}>

        {
          isSelectableResident() && isPrimaryContactConfirmed() && resident.status === ResidentStatus.ACTIVE ?
            <div className={`${styles.selectable} ${isSelectedResident() && styles.selectable_selected}`}>
              <img className={styles.icon} src={iconResidentSelectableCheck} />
            </div>
            :
            <></>
        }

        {
          isPrimaryContactConfirmed() && resident.status === ResidentStatus.ACTIVE ?
            <img className={styles.confirmedIcon} src={iconConfirmed} alt="confirmed"/>
            :
            <></>
        }

      {
        !isHasPrimary() && resident.status === ResidentStatus.ACTIVE ?
            <img className={styles.confirmedIcon} src={iconNoPrimaryContact} alt="The resident has no primary contact" title="The resident has no primary contact"/>
            :
            <></>
      }

        <CacheImg 
          url={resident.photo} 
          alt="guestPhoto" 
          className={styles.photo} 
          imgClassName={styles.photo} 
          skeletonProps={{variant: 'circular'}}
        >
          <div className={styles.noPhoto} style={{backgroundColor: stringToHslColor(resident.firstName + resident.lastName)}}>
            {resident.firstName[0]?.toUpperCase() || ''} {resident.lastName[0]?.toUpperCase() || ''}
          </div>
        </CacheImg>

        <div className={styles.info}>
            <span className={styles.name}>{resident.firstName} {resident.lastName}</span>
            
            <span className={styles.place}>
              Section <span className={styles.bold}>{resident.section}</span> - Room <span className={styles.bold}>{resident.room}</span>
            </span>
            
            <div className={styles.line}></div>

            <div className={styles.underLine}>
              {
                resident.status === ResidentStatus.ACTIVE ?
                  <span className={styles.newUpdate} onClick={(e) => onNewUpdate(e)}>New update</span>
                  :
                  <></>
              }

              <span className={styles.update}>Last update: {resident.lastUpdate ? moment(resident.lastUpdate).format("DD MMM YYYY") : "none"}</span>
            </div>
        </div>
    </div>
  )
})

export default ResidentCard;

interface ResidentCardProps {
  gridVariant: GridVariant;
  resident: IResident;
}

