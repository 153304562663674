import ResidentController from "@source/api/controllers/residentController";
import { IDownloadHomeUpdateHistoryRequest, IDownloadUnreadUpdateHistoryRequest, IGetAllUpdatesRequest, IGetUpdatesDataResponse, ResidentStatus } from "@source/api/models/residentModel";
import DateRangePicker from "@source/components/UI/inputs/dateRangePicker/dateRangePicker";
import SearchInput from "@source/components/UI/inputs/searchInput/searchInput";
import SelectInput from "@source/components/UI/inputs/selectInput/selectInput";
import Loader from "@source/components/UI/loader/loader";
import useFetching from "@source/hooks/useFetching";
import nursingHomeStore from "@source/store/nursingHomeStore";
import updatesStore from "@source/store/updatesStore";
import { IFillVariant } from "@source/utils/enums";
import moment from "moment";
import { observer } from "mobx-react-lite";
import Dropdown, { IDropdownItem } from "../../../../components/UI/dropdown/dropdown";
import iconExcel from "@source/assets/icons/iconExcel.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import HistoryCard from "../../../../components/historyCard/historyCard";
import styles from "./historyTab.module.scss";
import Svg from "@components/UI/svg/svg";
import iconDownload from "@source/assets/icons/iconDownload.svg";
import EmployeeController from "@source/api/controllers/employeeController";
import { ISelectItem } from "@components/UI/inputs/autocompleteInput/autocompleteInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import Checkbox from "@source/components/UI/buttons/checkbox/checkbox";
import { SelectChangeEvent } from "@mui/material";
import userStore from "@store/userStore";
import { HomePermissionsEnum } from "@source/api/models/permissionsModels";
import DownloadUnreadUpdateHistoryModal from "@components/downloadUnreadUpdateHistoryModal/downloadUnreadUpdateHistoryModal";
import FileSaver from 'file-saver';
import { DEFAULT_UPDATES_LIMIT } from "@source/api/services/residentService";
import useUpdatesLoading from "@source/hooks/useUpdatesLoading";
import StatisticController from "@source/api/controllers/statisticController";
import DownloadUpdateHistoryModal from "@components/downloadUpdateHistoryModal/downloadUpdateHistoryModal";
import residentsStore from "@store/residentsStore";
import LoaderComponent from "@components/loader/loader";

const HistoryTab = observer((props: HistoryTabProps) => {
  const {} = props;
  const [getUpdates, isLoading, error] = useFetching<ReturnType<typeof ResidentController.getAllUpdates>>(ResidentController.getAllUpdates);
  const [sentUpdatesCount, sentUpdatesCountIsLoading, sentUpdatesCountError] = useFetching<ReturnType<typeof ResidentController.getUpdatesSentCount>>(ResidentController.getUpdatesSentCount);
  const [getEmployeesNames, getEmployeesNamesLoading, getEmployeesNamesError] = useFetching<ReturnType<typeof EmployeeController.getNames>>(EmployeeController.getNames);
  const [downloadUnreadUpdateHistory, downloadUnreadUpdateHistoryLoading, downloadUnreadUpdateHistoryError] = useFetching<ReturnType<typeof ResidentController.downloadUnreadUpdateHistory>>(ResidentController.downloadUnreadUpdateHistory);
  const [getUpdatesStatistic, getUpdatesStatisticIsLoading, getUpdatesStatisticError] = useFetching<ReturnType<typeof StatisticController.getUpdatesStatistic>>(StatisticController.getUpdatesStatistic);
  const [downloadHomeUpdateHistory, downloadHomeUpdateHistoryLoading, downloadHomeUpdateHistoryError] = useFetching<ReturnType<typeof ResidentController.downloadHomeUpdateHistory>>(ResidentController.downloadHomeUpdateHistory);
  const router = useHistory();

  const [employeesNames, setEmployeesNames] = useState<ISelectItem[]>([]);
  const [updatesPage, setUpdatesPage] = useState<number>(0);
  const [nursingHomeId, setNursingHomeId] = useState<string>(nursingHomeStore.getId());
  const [requestData, setRequestData] = useState<any>({});
  const [downloadUpdatesModal, setDownloadUpdatesModal] = useState(false)
  
  useUpdatesLoading(
    getUpdates,
    (data: IGetUpdatesDataResponse) => updatesStore.setUpdatesData(data),
    (data: IGetUpdatesDataResponse) => updatesStore.addUpdatesData(data),
    { nursingHomeId, additionalRequestData: requestData, permission: true },
    updatesPage,
  );

  const getNewPageNumber = () => Math.ceil(updatesStore.updates.length / DEFAULT_UPDATES_LIMIT);

  useEffect(() => {
    setUpdatesPage(0);
    setRequestData({});
    setNursingHomeId(nursingHomeStore.getId());

    const fetchEmployeesNames = async () => {
      const getEmployeesNamesRes = await getEmployeesNames(nursingHomeStore.activeNursingHome?._id);

      if (getEmployeesNamesRes) {
        const getEmployeesNamesResSelectItems = getEmployeesNamesRes.map((employeeName) => {
          return {
            title: `${employeeName.firstName} ${employeeName.lastName}`,
            value: employeeName._id,
            id: employeeName._id,
          }
        });
        
        setEmployeesNames([
          {
            title: 'Staff members',
            value: 'all',
            id: '0',
          },
          {
            title: 'Agency staff',
            value: 'agencyStaff',
            id: '1',
          },
          ...getEmployeesNamesResSelectItems
        ])
      }
    }

    const fetchSentUpdatesCount = async () => {
      const getSentCountRes = await sentUpdatesCount(nursingHomeStore.activeNursingHome?._id)

      if(getSentCountRes) {
        updatesStore.setTotalSent(getSentCountRes.totalSent);
      }
    }

    resetFilters();
    fetchEmployeesNames();
    fetchSentUpdatesCount()
  }, [nursingHomeStore.activeNursingHome])

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler, { capture: true });
    return function() {
      document.removeEventListener('scroll', scrollHandler, { capture: true });
    }
  }, [])
  
  const scrollHandler = (e) => {
    if (e.target.scrollHeight - (e.target.scrollTop + window.innerHeight) < 100 && updatesStore.updates.length < updatesStore.totalCount) {
      setUpdatesPage(getNewPageNumber());
    }
  }

  const selectItems = useMemo(
    () => [
      {
        title: "All priority",
        value: "all",
        id: 1,
      },
      {
        title: "High",
        value: "critical",
        id: 2,
      },
      {
        title: "Medium",
        value: "major",
        id: 3,
      },
      {
        title: "Low",
        value: "minor",
        id: 4,
      },
    ],
    []
  );

  const [search, setSearch] = useState("");
  const [filtersDirty, setFiltersDirty] = useState(false);
  const [downloadUnreadUpdatesModal, setDownloadUnreadUpdatesModal] = useState(false);

  const validateSchema = Yup.object({
  });

  const mainFormik = useFormik({
    validationSchema: validateSchema,
    validateOnChange: true,
    validateOnBlur: true, 
    initialValues: {
      priority: '',
      dateRange: null,
      isRead: null,
      employee: "",
    },
    onSubmit: () => {

    }
  });

  useEffect(() => {
    const { priority, dateRange, isRead, employee } = mainFormik.values;

    if (filtersDirty) {
      const fetchUpdates = async () => {
        const request: IGetAllUpdatesRequest = {
          nursingHomeId: nursingHomeStore.getId(),
          searchString: search,
          page: 0,
          priority: (!priority || priority === selectItems[0].value) ? "" : priority,
          startDate: dateRange ? moment(dateRange[0]).startOf("day").toISOString() : "",
          endDate: dateRange && dateRange[1] ? moment(dateRange[1]).endOf("day").toISOString() : "",
          isRead: isRead ? false : null,
          isAgency: employee === employeesNames[1]?.value ? true : null,
          employeeIds: employee && employee !== employeesNames[0]?.value && employee !== employeesNames[1]?.value ? [employee] : [],
        };

        setRequestData(request);
        setUpdatesPage(0);
      };

      const timer = setTimeout(() => {
        fetchUpdates();
      }, 500)

      return () => {
        clearTimeout(timer);
      }
    }
  }, [search]);

  useEffect(() => {
    const { priority, dateRange, isRead, employee } = mainFormik.values;

    if (filtersDirty) {
      const fetchUpdates = async () => {
        const request: IGetAllUpdatesRequest = {
          nursingHomeId: nursingHomeStore.getId(),
          searchString: search,
          page: 0,
          priority: priority === selectItems[0].value ? "" : priority,
          startDate: dateRange ? moment(dateRange[0]).startOf("day").toISOString() : "",
          endDate: dateRange && dateRange[1] ? moment(dateRange[1]).endOf("day").toISOString() : "",
          isRead: isRead ? false : null,
          isAgency: employee === employeesNames[1]?.value ? true : null,
          employeeIds: employee && employee !== employeesNames[0]?.value && employee !== employeesNames[1]?.value ? [employee] : [],
        };

        setRequestData(request);
        setUpdatesPage(0);
      };
  
      fetchUpdates();
    }

  }, [mainFormik.values]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    if (!filtersDirty) {
      setFiltersDirty(true);
    }
  };

  const onDateRangeChange = (dateRange: [Date, Date] | null) => {
    mainFormik.setFieldValue('dateRange', dateRange);
    
    if (!filtersDirty) {
      setFiltersDirty(true);
    }
  }

  const onSelectChange = (e: SelectChangeEvent<string>) => {
    const selectedItem = selectItems.find((selectItem) => selectItem.value === e.target.value);

    if (selectedItem) {
      mainFormik.setFieldValue('priority', selectedItem.value);
    }
        
    if (!filtersDirty) {
      setFiltersDirty(true);
    }
  };

  const onSelectStaffChange = (e: SelectChangeEvent<string>) => {
    const selectedStaffItem = employeesNames.find((employeesName) => employeesName.value === e.target.value);

    if (selectedStaffItem) {
      mainFormik.setFieldValue('employee', selectedStaffItem.value);
    }
        
    if (!filtersDirty) {
      setFiltersDirty(true);
    }
  };

  const onTabUpdateDelete = async (updateId: string) => {
    updatesStore.deleteUpdate(updateId);
    setUpdatesPage(0);
  }

  const resetFilters = () => {
    setSearch('');
    mainFormik.resetForm();
    setFiltersDirty(false);
  }

  const clearFilters = () => {
    mainFormik.resetForm();
    setSearch('');
  }

  const handleCheckboxOnlyReadChange = (e) => {
    mainFormik.setFieldValue("isRead", !mainFormik.values.isRead);
        
    if (!filtersDirty) {
      setFiltersDirty(true);
    }
  }

  const getFiltersCounter = () => {
    const { priority, dateRange, isRead, employee } = mainFormik.values;

    let counter = 0;

    if (search) {
      counter++;
    };

    if (priority && priority !== selectItems[0].value) {
      counter++;
    };

    if (dateRange) {
      counter++;
    }

    if (isRead) {
      counter++;
    }
    
    if (employee && employee !== employeesNames[0]?.value) {
      counter++;
    }

    return counter;
  }

  const toggleDownloadUpdatesModal = () => {
    setDownloadUnreadUpdatesModal(!downloadUnreadUpdatesModal);
  }

  const onDownloadUnreadUpdateHistory = async (req: IDownloadUnreadUpdateHistoryRequest) => {
    const downloadResidentUpdateHistoryRes = await downloadUnreadUpdateHistory(nursingHomeStore.activeNursingHome?._id, req);

    if (downloadResidentUpdateHistoryRes) {
      let blob =  new Blob([downloadResidentUpdateHistoryRes], { type: req.type })

      FileSaver.saveAs(blob, `unread_updates_report_${nursingHomeStore.activeNursingHome?.name ?? ''}_${moment().format("DD.MM.YYYY")}.xlsx`)
    }
  }

  async function onDonwloadStaffUpdatesStatistic() {
    const getUpdatesStatisticRes = await getUpdatesStatistic(nursingHomeStore.activeNursingHome?._id || '');

    if (getUpdatesStatisticRes) {
      let blob =  new Blob([getUpdatesStatisticRes], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })

      FileSaver.saveAs(blob, `update_creation_report_${nursingHomeStore.activeNursingHome?.name ?? ''}_${moment().format("DD.MM.YYYY")}.xlsx`)
    }
  }


  const toggleDownloadUpdatesHistoryModal = () => {
    setDownloadUpdatesModal(!downloadUpdatesModal);
  }

  const onDownloadHomeUpdateHistory = async (req: IDownloadHomeUpdateHistoryRequest) => {
    const downloadResidentUpdateHistoryRes = await downloadHomeUpdateHistory(nursingHomeStore.activeNursingHome?._id, req);

    if (downloadResidentUpdateHistoryRes) {
      let blob =  new Blob([downloadResidentUpdateHistoryRes], { type: req.type })

      FileSaver.saveAs(blob, `full_updates_history_report_${nursingHomeStore.activeNursingHome?.name ?? ''}_${moment().format("DD.MM.YYYY")}.${req.type}`)
    }
  }

  const getDropdownItems = () => {
    const items: IDropdownItem[] = [];
    if (userStore.isPermission(HomePermissionsEnum.DOWNLOAD_UNREAD_UPDATES)) {
      items.push({
        icon: iconDownload,
        onClick: toggleDownloadUpdatesModal,
        text: "Unread updates",
        className: styles.downloadUnreadUpdates,
        iconColor: "#3956C7",
      });
    }

    if (userStore.isPermission(HomePermissionsEnum.VIEW_HOME_STATISTIC)) {
      items.push({
        onClick: onDonwloadStaffUpdatesStatistic,
        text: "Update creation report",
        childrenImage: (
          <img width={20} height={20} src={iconExcel} alt="documentIcon"/>
        ),
        className: styles.downloadUnreadUpdates,
        isLoading: getUpdatesStatisticIsLoading,
      });
    }

    if (userStore.isPermission(HomePermissionsEnum.DOWNLOAD_UPDATE_HISTORY)) {
      items.push({
        onClick: toggleDownloadUpdatesHistoryModal,
        text: "Download update history (pdf, docs)",
        childrenImage: (
          <img width={20} height={20} src={iconDownload} alt="downloadIcon"/>
        ),
        className: styles.downloadUnreadUpdates,
      });
    }
    return items;
  }

  const {errors, values, touched, handleBlur, handleChange, handleSubmit, isValid} = mainFormik;
  return(
    <div className={styles.history}>
      <DownloadUnreadUpdateHistoryModal open={downloadUnreadUpdatesModal} setOpen={toggleDownloadUpdatesModal} onDownload={onDownloadUnreadUpdateHistory} isLoading={downloadUnreadUpdateHistoryLoading}/>
      <DownloadUpdateHistoryModal open={downloadUpdatesModal} setOpen={toggleDownloadUpdatesHistoryModal} onDownload={onDownloadHomeUpdateHistory} isLoading={downloadHomeUpdateHistoryLoading}/>

      <div className={styles.filters}>
        <div className={styles.visibleFilters}>
          <SearchInput className={styles.search} value={search}
                onChange={onSearchChange} fill={IFillVariant.Fill} 
                placeHolder="Search"/>

          {
            getFiltersCounter() > 0 ?
              <div className={styles.clearFilters} onClick={clearFilters}>
                <span className={styles.text}>Clear filters ({getFiltersCounter()})</span>
              </div>
              :
              <></>
          }
        </div>

        <div className={`${styles.hiddenFilters}`}>
          <form className={styles.form} onSubmit={handleSubmit}>
            <SelectInput className={`${styles.priority} ${!values.priority && styles.priority_placeholder}`} value={values.priority} 
                  handleChange={onSelectChange} id="residentsSelect" 
                  items={selectItems} placeholder="Priority"/>

            <SelectInput className={`${styles.staff} ${!values.employee && styles.staff_placeholder}`} placeholder="Staff members" value={values.employee} 
                  handleChange={onSelectStaffChange} id="staffSelect" 
                  items={employeesNames}/>

            <DateRangePicker className={styles.dateRange} value={values.dateRange} maxDate={new Date()}
                  onChange={onDateRangeChange} fill={IFillVariant.Fill} id="dateRange" />

            <Checkbox
              label="Only unread"
              value={String(values.isRead)}
              className={styles.radio_input}
              onChange={handleCheckboxOnlyReadChange}
              checked={!!values.isRead}
              id="isRead"
            />
          </form>
          {
            userStore.isPermission(HomePermissionsEnum.DOWNLOAD_UNREAD_UPDATES) ||
            userStore.isPermission(HomePermissionsEnum.VIEW_HOME_STATISTIC) ?
              <Dropdown className={styles.dropdown} items={getDropdownItems()} arrow>
                  <span className={styles.dropdown__text}>Download reports</span>
              </Dropdown>
            : <></>
          }
        </div>
      </div>

      <div className={styles.list}>
        {updatesStore.updates?.length
          ? updatesStore.updates.map((update) => (
              <HistoryCard 
                residentStatus={ResidentStatus.ACTIVE}
                key={update._id}
                className={styles.card} 
                onTabUpdateDelete={onTabUpdateDelete} 
                isChatIcon={false}
                update={update} 
                /> 
            ))
          : <></>}
      </div>

      {isLoading
        ? <div className={styles.loader}>
            <Loader width={80} height={80} borderWidth={6}/>
          </div>
        : <></>}
    </div>
  );
});

export default HistoryTab;

interface HistoryTabProps {}
