import { getUpdateSchema } from "@source/pages/HomePage/homeRouter/updateTab/updateSchema/updateFormSchema";
import { 
  Sections, 
  IUpdateStep, 
  UpdateInputs, 
  UpdateInputsTypes, 
  SubSections, 
  SectionsId, 
  SubSectionsId, 
  Infection_SubSectionsId, 
  GP_Review_Medication_Change_SubSectionsId,
  Fall_SubSections,
  Fall_SubSectionsId,
  Infection_SubSections,
  GP_Review_Medication_Change_SubSections,
  UpdatePriorities,
  UpdatePrioritiesId,
  Incident_SubSectionsId,
  Incident_SubSections,
  Change_To_Condition_SubSections,
  Change_To_Condition_SubSectionsId,
  Medication_Incidents_SubSectionsId,
  Medication_Incidents_SubSections,
  Pain_SubSections,
  Pain_SubSectionsId,
  UpdateInput,
  IUpdateValues,
  UpdateMessageSymbols,
  Physiotherapy_Review_SubSections,
  Physiotherapy_Review_SubSectionsId,
  Request_For_Items_SubSections,
  Personal_Care_SubSections,
  Nutritional_SubSections,
  Specialist_Appointment_SubSections,
  Other_SubSections,
  Request_For_Items_SubSectionsId,
  Personal_Care_SubSectionsId,
  Nutritional_SubSectionsId,
  Specialist_Appointment_SubSectionsId,
  Other_SubSectionsId,
  Resident_Of_The_Day_SubSectionsId,
  Return_From_Hospital_SubSections,
  Return_From_Hospital_SubSectionsId,
  Resident_Of_The_Day_SubSections,
} from "@source/pages/HomePage/homeRouter/updateTab/updateSchema/updateFormModels";
import { IMainUpdateForm } from "@source/pages/HomePage/homeRouter/updateTab/updateTab";
import styles from "./updateForm.module.scss";
import { deepClone } from "@source/utils/utils";

const UpdateSchema = getUpdateSchema()

export function getSections() {
  return Object.keys(UpdateSchema).map((section) => section)
}

export function getSubSections(section: Sections):Array<SubSections> {
  return Object.keys(UpdateSchema[section]).map((subSection) => subSection) as Array<SubSections>
}

export function generateStepInitialValues(inputs: UpdateInputs): IUpdateValues {
  const addInputsValues = (inputs: UpdateInputs) => {
    let values: IUpdateValues = {};

    inputs.forEach((input) => {
      switch(input.type) {
        case UpdateInputsTypes.Checkbox: {
          values[input.name] = input.checked;
          break;
        }
        case UpdateInputsTypes.CheckboxInput: {
          values[input.name] = input.value;
  
          if(input.dependentInputs) {
            Object.values(input.dependentInputs).forEach((inputs) => {
              values = {...values, ...addInputsValues(inputs)}
            })
          }
          break;
        }
        case UpdateInputsTypes.RadioInput: {
          values[input.name] = input.value;
  
          if(input.dependentInputs) {
            Object.values(input.dependentInputs).forEach((inputs) => {
              values = {...values, ...addInputsValues(inputs)}
            })
          }
          break;
        }
        case UpdateInputsTypes.File: {
          values['files'] = input.value || [];
          break;
        }
        default: {
          values[input.name] = input.value;
          break;
        }
      }
    })

    return values
  }
  return addInputsValues(inputs)
}

export function generateValidationSchema(inputs: UpdateInputs, values: IUpdateValues) {
  let validateSchema = {};

  const addValidationSchema = (input: UpdateInput) => {
    const {name, validationSchema} = input;
    validateSchema[name] = validationSchema;
  }

  const addDependentInputs = (inputs: UpdateInputs) => {
    inputs.forEach((dependentInput) => {
      addValidationSchema(dependentInput)
    });

    validateSchema = {...validateSchema, ...generateValidationSchema(inputs, values)};
  }

  for (let i=0; i<inputs.length; i++) {
    const input = inputs[i];

    if(input.validationSchema) {
      if(checkInputCondition(inputs, input, values)) {
        addValidationSchema(input);
      }
    }

    if('dependentInputs' in input && input?.dependentInputs && values[input.name]) {
      for(let nameDependentValue in input.dependentInputs) {
        if(Array.isArray(values[input.name])) {
          if(values[input.name].includes(nameDependentValue)) {
            addDependentInputs(input.dependentInputs[nameDependentValue]);
          }
        } else {
          if(values[input.name] === nameDependentValue) {
            addDependentInputs(input.dependentInputs[nameDependentValue]);
          }
        }

      }
    }
    
  }

  return validateSchema;
}

export function checkInputCondition(inputs: UpdateInputs, input: UpdateInput, values: IUpdateValues) {
  if(input.conditions) {
    for (let i=0; i<input.conditions.length; i++) {
      const conditionName = Object.keys(input.conditions[i])[0];
      const conditionInputType = inputs.find((input) => input.name === conditionName)?.type;

      if(conditionInputType === UpdateInputsTypes.CheckboxInput) {
        if(!values[conditionName]?.includes(input.conditions[i][conditionName])) {
          return false;
        }
      } else {
        if(values[conditionName] !== input.conditions[i][conditionName]) {
          return false;
        }
      }
    }
  } 

  return true;
}

export function getSectionNameById(sectionId: SectionsId): Sections {
  const Categories = {
    [SectionsId.Fall]: Sections.Fall,
    [SectionsId.Infection]: Sections.Infection,
    [SectionsId.GP_Review_Medication_Change]: Sections.GP_Review_Medication_Change,
    [SectionsId.Incident]: Sections.Incident,
    [SectionsId.Change_To_Condition]: Sections.Change_To_Condition,
    [SectionsId.Medication_Incidents]: Sections.Medication_Incidents,
    [SectionsId.Pain]: Sections.Pain,
    [SectionsId.Physiotherapy_Review]: Sections.Physiotherapy_Review,
    [SectionsId.Request_For_Items]: Sections.Request_For_Items,
    [SectionsId.Personal_Care]: Sections.Personal_Care,
    [SectionsId.Nutritional]: Sections.Nutritional,
    [SectionsId.Specialist_Appointment]: Sections.Specialist_Appointment,
    [SectionsId.Other]: Sections.Other,
    [SectionsId.Resident_Of_The_Day]: Sections.Resident_Of_The_Day,
    [SectionsId.Return_From_Hospital]: Sections.Return_From_Hospital,
  }

  return Categories[sectionId]
}

export function getViewUpdateCategoryName(section: Sections | '', subSection: SubSections | '', viewName?: string) {
  const sections = {
    [Sections.Fall]: {
      [Fall_SubSections.Fall]: {category: 'Fall', subcategory: '', updateCategoryName: 'Fall'}
    },
    [Sections.Infection]: {
      [Infection_SubSections.Urinary_Infection]: {category: 'Infection', subcategory: 'Urinary', updateCategoryName: 'Urinary Infection'},
      [Infection_SubSections.Respiratory]: {category: 'Infection', subcategory: 'Respiratory', updateCategoryName: 'Respiratory Infection'},
      [Infection_SubSections.Wound]: {category: 'Infection', subcategory: 'Wound', updateCategoryName: 'Wound Infection'},
      [Infection_SubSections.Skin_Infection]: {category: 'Infection', subcategory: 'Skin infection', updateCategoryName: 'Skin Infection'},
      [Infection_SubSections.Cellulitis]: {category: 'Infection', subcategory: 'Cellulitis', updateCategoryName: 'Cellulitis Infection'},
      [Infection_SubSections.Ear_Nose_Throat_ENT]: {category: 'Infection', subcategory: 'Ear, Nose, Throat ENT', updateCategoryName: 'Ear, Nose and Throat Infection'},
      [Infection_SubSections.Eye]: {category: 'Infection', subcategory: 'Eye', updateCategoryName: 'Eye Infection'},
      [Infection_SubSections.Covid_First_Update_To_Family]: {category: 'Infection', subcategory: 'Covid-19 Infection -  First Update', updateCategoryName: 'Covid-19 Infection -  First Update'},
      [Infection_SubSections.Covid_Ongoing_Update]: {category: 'Infection', subcategory: 'Covid-19 Infection - Ongoing Update', updateCategoryName: 'Covid-19 Infection - Ongoing Update'},
      [Infection_SubSections.Other]: {category: 'Infection', subcategory: 'Unknown infection', updateCategoryName: 'Unknown Infection'},
    },
    [Sections.GP_Review_Medication_Change]: {
      [GP_Review_Medication_Change_SubSections.General_Review]: {category: 'GP Update', subcategory: 'General', updateCategoryName: 'GP Update - General'},
      [GP_Review_Medication_Change_SubSections.Deterioration_Physical_Or_Cognitive]: {category: 'GP Update', subcategory: 'Condition', updateCategoryName: 'GP Update - Condition'},
      [GP_Review_Medication_Change_SubSections.Medication_Review]: {category: 'GP Update', subcategory: 'Medication', updateCategoryName: 'GP Update - Medication'} ,
      [GP_Review_Medication_Change_SubSections.Review_Post_Fall_Or_Incident]: {category: 'GP Update', subcategory: 'After Incident', updateCategoryName: 'GP Update - After Incident'} ,
      [GP_Review_Medication_Change_SubSections.Other]: {category: 'GP Update', subcategory: 'Other', updateCategoryName: 'GP Update - Other'},
    },
    [Sections.Incident]: {
      [Incident_SubSections.Bruising]: {category: 'Incident', subcategory: 'Bruising', updateCategoryName: 'Incident - Bruising'},
      [Incident_SubSections.Skin_Tear]: {category: 'Incident', subcategory: 'Skin Tear', updateCategoryName: 'Incident - Skin Tear'},
      [Incident_SubSections.Pressure_Injury]: {category: 'Incident', subcategory: 'Pressure Injury', updateCategoryName: 'Incident - Pressure Injury'},
      [Incident_SubSections.Ulcer]: {category: 'Incident', subcategory: 'Ulcer', updateCategoryName: 'Incident - Ulcer'},
      [Incident_SubSections.Absconding]: {category: 'Incident', subcategory: 'Absconding', updateCategoryName: 'Incident - Absconding'},
      [Incident_SubSections.Behaviour]: {category: 'Incident', subcategory: 'Behaviour (aggressive, violent)', updateCategoryName: 'Incident - Behaviour (Agressive / Violent)'},
      [Incident_SubSections.Choking]: {category: 'Incident', subcategory: 'Choking', updateCategoryName: 'Incident - Choking'} ,
      [Incident_SubSections.Other]: {category: 'Incident', subcategory: 'Other incident', updateCategoryName: 'Incident - Other'},
    },
    [Sections.Change_To_Condition]: {
      [Change_To_Condition_SubSections.Change_To_Cognition]: {category: 'Condition Update', subcategory: 'Cognition', updateCategoryName: 'Condition Update - Cognition'},
      [Change_To_Condition_SubSections.Change_To_Mobility]: {category: 'Condition Update', subcategory: 'Mobility', updateCategoryName: 'Condition Update - Mobility'},
      [Change_To_Condition_SubSections.Change_To_Appetite]: {category: 'Condition Update', subcategory: 'Appetite', updateCategoryName: 'Condition Update - Appetite'},
      [Change_To_Condition_SubSections.Change_To_Behaviour_Mood]: {category: 'Condition Update', subcategory: 'Behaviour / Mood', updateCategoryName: 'Condition Update - Behaviour / Mood'},
      [Change_To_Condition_SubSections.Weight_Change_Update]: {category: 'Condition Update', subcategory: 'Weight', updateCategoryName: 'Condition Update - Weight'},
      [Change_To_Condition_SubSections.Other_General_Update]: {category: 'Condition Update', subcategory: 'General', updateCategoryName: 'Condition Update - General'},
    },
    [Sections.Medication_Incidents]: {
      [Medication_Incidents_SubSections.Medication_Incidents]: {category: 'Medication incidents', subcategory: '', updateCategoryName: 'Medication incident'}
    },
    [Sections.Pain]: {
      [Pain_SubSections.Pain]: {category: 'Pain', subcategory: '', updateCategoryName: 'Pain'}
    },
    [Sections.Physiotherapy_Review]: {
      [Physiotherapy_Review_SubSections.General_Update_From_Physio]: {category: 'Physiotherapy Review', subcategory: 'General Update from Physio', updateCategoryName: 'Physiotherapy - General Update from Physio'},
      [Physiotherapy_Review_SubSections.New_Aid_Walker]: {category: 'Physiotherapy Review', subcategory: 'New Aid / Walker', updateCategoryName: 'Physiotherapy - New Aid / Walker'},
      [Physiotherapy_Review_SubSections.New_Exercises]: {category: 'Physiotherapy Review', subcategory: 'New Exercises', updateCategoryName: 'Physiotherapy - New Exercises'},
      [Physiotherapy_Review_SubSections.Mobility_ReAssessed]: {category: 'Physiotherapy Review', subcategory: 'Mobility re-assessed', updateCategoryName: 'Physiotherapy - Mobility re-assessed'},
    },
    [Sections.Request_For_Items]: {
      [Request_For_Items_SubSections.Clothing_Footwear]: {category: 'Request for Items', subcategory: 'Clothing/Footwear', updateCategoryName: 'Items - Clothing / Footwear'},
      [Request_For_Items_SubSections.Food_Items]: {category: 'Request for Items', subcategory: 'Food Items', updateCategoryName: 'Items - Food items'},
      [Request_For_Items_SubSections.Personal_Care_Items]: {category: 'Request for Items', subcategory: 'Personal care items', updateCategoryName: 'Items - Personal care items'},
      [Request_For_Items_SubSections.Other]: {category: 'Request for Items', subcategory: 'Other', updateCategoryName: 'Items - Other'},
    },
    [Sections.Personal_Care]: {
      [Personal_Care_SubSections.Change_In_Habits]: {category: 'Personal Care Update', subcategory: 'Change in continence/bowel habits', updateCategoryName: 'Personal Care - Change in continence/bowel habits'},
      [Personal_Care_SubSections.Wearing_Of_Pads]: {category: 'Personal Care Update', subcategory: 'Wearing of Pads', updateCategoryName: 'Personal Care - Wearing of Pads'},
      [Personal_Care_SubSections.Change_In_Personal_Hygiene_Needs]: {category: 'Personal Care Update', subcategory: 'Change in personal hygiene needs (e.g. needs help with dressing etc)', updateCategoryName: 'Personal Care - Change in personal hygiene needs'},
    },
    [Sections.Nutritional]: {
      [Nutritional_SubSections.Nutritional]: {category: 'Nutritional Update', subcategory: '', updateCategoryName: 'Nutritional Update'},
    },
    [Sections.Specialist_Appointment]: {
      [Specialist_Appointment_SubSections.Referral_To_Allied_Health_Professiona]: {category: 'Allied Health Professional Appointment', subcategory: '', updateCategoryName: 'Allied Health Professional Appointment'},
      [Specialist_Appointment_SubSections.Referral_To_Specialist]: {category: 'Specialist Appointment', subcategory: '', updateCategoryName: 'Specialist Appointment'},
    },
    [Sections.Return_From_Hospital]: {
      [Return_From_Hospital_SubSections.Return_From_Hospital]: {category: 'Return from Hospital', subcategory: '', updateCategoryName: 'Return from Hospital'}
    },
    [Sections.Other]: {
      [Other_SubSections.Other]: {category: 'Other Update', subcategory: '', updateCategoryName: 'Other Update'}
    },
    [Sections.Resident_Of_The_Day]: {
      [Resident_Of_The_Day_SubSections.Resident_Of_The_Day]: {category: 'Resident of the Day ', subcategory: '', updateCategoryName: 'Resident of the Day '}
    }
  }

  return {...sections[section][subSection], updateCategoryName: viewName || sections[section][subSection].updateCategoryName}
}

export function getUpdateCategoryNameById(sectionId:SectionsId, subSectionId: SubSectionsId) {
  const SubCategoriesNames = {
    [SectionsId.Fall]: {
      [Fall_SubSectionsId.Fall]: 'Fall'
    },
    [SectionsId.Infection]: {
      [Infection_SubSectionsId.Urinary_Infection]: 'Infection - Urinary Infection ',
      [Infection_SubSectionsId.Respiratory]: 'Infection - Respiratory',
      [Infection_SubSectionsId.Wound]: 'Infection - Wound',
      [Infection_SubSectionsId.Skin_Infection]: 'Infection - Skin Infection',
      [Infection_SubSectionsId.Cellulitis]: 'Infection - Cellulitis ',
      [Infection_SubSectionsId.Ear_Nose_Throat_ENT]: 'Infection - Ear, Nose, Throat ENT',
      [Infection_SubSectionsId.Covid_First_Update_To_Family]: 'Infection - Covid-19 First Update to Family',
      [Infection_SubSectionsId.Covid_Ongoing_Update]: 'Infection - Covid-19 Ongoing Update',
      [Infection_SubSectionsId.Eye]: 'Infection - Eye',
      [Infection_SubSectionsId.Other]: 'Infection - Unknown',
    },
    [SectionsId.GP_Review_Medication_Change]: {
      [GP_Review_Medication_Change_SubSectionsId.General_Review]: 'GP Review / Medication Change - General Review',
      [GP_Review_Medication_Change_SubSectionsId.Deterioration_Physical_Or_Cognitive]: 'GP Review / Medication Change - Deterioration Physical or Cognitive ',
      [GP_Review_Medication_Change_SubSectionsId.Medication_Review]: 'GP Review / Medication Change - Medication Review',
      [GP_Review_Medication_Change_SubSectionsId.Review_Post_Fall_Or_Incident]: 'GP Review / Medication Change - Review Post Fall or Incident',
      [GP_Review_Medication_Change_SubSectionsId.Other]: 'GP Review / Medication Change - Other',
    },
    [SectionsId.Incident]: {
      [Incident_SubSectionsId.Bruising]: 'Incident - Bruising',
      [Incident_SubSectionsId.Skin_Tear]: 'Incident - Skin Tear',
      [Incident_SubSectionsId.Pressure_Injury]: 'Incident - Pressure Injury',
      [Incident_SubSectionsId.Ulcer]: 'Incident - Ulcer',
      [Incident_SubSectionsId.Absconding]: 'Incident - Absconding',
      [Incident_SubSectionsId.Behaviour]: 'Incident - Behaviour (Agressive / Violent)',
      [Incident_SubSectionsId.Choking]: 'Incident - Choking',
      [Incident_SubSectionsId.Other]: 'Incident - Other',
    },
    [SectionsId.Change_To_Condition]: {
      [Change_To_Condition_SubSectionsId.Change_To_Cognition]: 'Change to condition - Change to cognition',
      [Change_To_Condition_SubSectionsId.Change_To_Mobility]: 'Change to condition - Change to mobility',
      [Change_To_Condition_SubSectionsId.Change_To_Appetite]: 'Change to condition - Change to appetite',
      [Change_To_Condition_SubSectionsId.Change_To_Behaviour_Mood]: 'Change to condition - change to behaviour / mood',
      [Change_To_Condition_SubSectionsId.Weight_Change_Update]: 'Change to condition - Weight change update',
      [Change_To_Condition_SubSectionsId.Other_General_Update]: 'Change to condition - other / general update',
    },
    [SectionsId.Medication_Incidents]: {
      [Medication_Incidents_SubSectionsId.Medication_Incidents]: 'Medication Incidents'
    },
    [SectionsId.Pain]: {
      [Pain_SubSectionsId.Pain]: 'Pain'
    },
    [SectionsId.Physiotherapy_Review]: {
      [Physiotherapy_Review_SubSectionsId.General_Update_From_Physio]: 'Physiotherapy Review: General Update from Physio',
      [Physiotherapy_Review_SubSectionsId.New_Aid_Walker]: 'Physiotherapy Review: New Aid / Walker',
      [Physiotherapy_Review_SubSectionsId.New_Exercises]: 'Physiotherapy Review: New Exercises',
      [Physiotherapy_Review_SubSectionsId.Mobility_ReAssessed]: 'Physiotherapy Review: Mobility re-assessed',
    },
    [SectionsId.Request_For_Items]: {
      [Request_For_Items_SubSectionsId.Clothing_Footwear]: 'Request for Items - Clothing / Footwear',
      [Request_For_Items_SubSectionsId.Food_Items]: 'Request for Items - Food items',
      [Request_For_Items_SubSectionsId.Personal_Care_Items]: 'Request for Items - Personal care items',
      [Request_For_Items_SubSectionsId.Other]: 'Request for Items - Other',
    },
    [SectionsId.Personal_Care]: {
      [Personal_Care_SubSectionsId.Change_In_Habits]: 'Personal Care Update: Change in continence/bowel habits',
      [Personal_Care_SubSectionsId.Wearing_Of_Pads]: 'Personal Care Update: Wearing of Pads',
      [Personal_Care_SubSectionsId.Change_In_Personal_Hygiene_Needs]: 'Personal Care Update: Change in personal hygiene needs (e.g. needs help with dressing etc)',
    },
    [SectionsId.Nutritional]: {
      [Nutritional_SubSectionsId.Nutritional]: 'Nutritional Update',
    },
    [SectionsId.Specialist_Appointment]: {
      [Specialist_Appointment_SubSectionsId.Referral_To_Allied_Health_Professiona]: 'Specialist / Allied Health Professional Appointment - Allied Health Professional Appointment',
      [Specialist_Appointment_SubSectionsId.Referral_To_Specialist]: 'Specialist / Allied Health Professional Appointment - Specialist Appointment',
    },
    [SectionsId.Return_From_Hospital]: {
      [Return_From_Hospital_SubSectionsId.Return_From_Hospital]: 'Return from Hospital'
    },
    [SectionsId.Resident_Of_The_Day]: {
      [Resident_Of_The_Day_SubSectionsId.Resident_Of_The_Day]: 'Resident of the Day'
    },
    [SectionsId.Other]: {
      [Other_SubSectionsId.Other]: 'Other update'
    },
  }
  
  return SubCategoriesNames[sectionId][subSectionId]
}

export function getSubSectionNameById(sectionId:SectionsId, subSectionId: SubSectionsId): SubSections {
  const SubCategories = {
    [SectionsId.Fall]: {
      [Fall_SubSectionsId.Fall]: Fall_SubSections.Fall
    },
    [SectionsId.Infection]: {
      [Infection_SubSectionsId.Urinary_Infection]: Infection_SubSections.Urinary_Infection,
      [Infection_SubSectionsId.Respiratory]: Infection_SubSections.Respiratory,
      [Infection_SubSectionsId.Wound]: Infection_SubSections.Wound,
      [Infection_SubSectionsId.Skin_Infection]: Infection_SubSections.Skin_Infection,
      [Infection_SubSectionsId.Cellulitis]: Infection_SubSections.Cellulitis,
      [Infection_SubSectionsId.Ear_Nose_Throat_ENT]: Infection_SubSections.Ear_Nose_Throat_ENT,
      [Infection_SubSectionsId.Covid_First_Update_To_Family]: Infection_SubSections.Covid_First_Update_To_Family,
      [Infection_SubSectionsId.Covid_Ongoing_Update]: Infection_SubSections.Covid_Ongoing_Update,
      [Infection_SubSectionsId.Eye]: Infection_SubSections.Eye,
      [Infection_SubSectionsId.Other]: Infection_SubSections.Other,
    },
    [SectionsId.GP_Review_Medication_Change]: {
      [GP_Review_Medication_Change_SubSectionsId.General_Review]: GP_Review_Medication_Change_SubSections.General_Review,
      [GP_Review_Medication_Change_SubSectionsId.Deterioration_Physical_Or_Cognitive]: GP_Review_Medication_Change_SubSections.Deterioration_Physical_Or_Cognitive,
      [GP_Review_Medication_Change_SubSectionsId.Medication_Review]: GP_Review_Medication_Change_SubSections.Medication_Review,
      [GP_Review_Medication_Change_SubSectionsId.Review_Post_Fall_Or_Incident]: GP_Review_Medication_Change_SubSections.Review_Post_Fall_Or_Incident,
      [GP_Review_Medication_Change_SubSectionsId.Other]: GP_Review_Medication_Change_SubSections.Other,
    },
    [SectionsId.Incident]: {
      [Incident_SubSectionsId.Bruising]: Incident_SubSections.Bruising,
      [Incident_SubSectionsId.Skin_Tear]: Incident_SubSections.Skin_Tear,
      [Incident_SubSectionsId.Pressure_Injury]: Incident_SubSections.Pressure_Injury,
      [Incident_SubSectionsId.Ulcer]: Incident_SubSections.Ulcer,
      [Incident_SubSectionsId.Absconding]: Incident_SubSections.Absconding,
      [Incident_SubSectionsId.Behaviour]: Incident_SubSections.Behaviour,
      [Incident_SubSectionsId.Choking]: Incident_SubSections.Choking,
      [Incident_SubSectionsId.Other]: Incident_SubSections.Other,
    },
    [SectionsId.Change_To_Condition]: {
      [Change_To_Condition_SubSectionsId.Change_To_Cognition]: Change_To_Condition_SubSections.Change_To_Cognition,
      [Change_To_Condition_SubSectionsId.Change_To_Mobility]: Change_To_Condition_SubSections.Change_To_Mobility,
      [Change_To_Condition_SubSectionsId.Change_To_Appetite]: Change_To_Condition_SubSections.Change_To_Appetite,
      [Change_To_Condition_SubSectionsId.Change_To_Behaviour_Mood]: Change_To_Condition_SubSections.Change_To_Behaviour_Mood,
      [Change_To_Condition_SubSectionsId.Weight_Change_Update]: Change_To_Condition_SubSections.Weight_Change_Update,
      [Change_To_Condition_SubSectionsId.Other_General_Update]: Change_To_Condition_SubSections.Other_General_Update,
    },
    [SectionsId.Medication_Incidents]: {
      [Medication_Incidents_SubSectionsId.Medication_Incidents]: Medication_Incidents_SubSections.Medication_Incidents
    },
    [SectionsId.Pain]: {
      [Pain_SubSectionsId.Pain]: Pain_SubSections.Pain
    },
    [SectionsId.Physiotherapy_Review]: {
      [Physiotherapy_Review_SubSectionsId.General_Update_From_Physio]: Physiotherapy_Review_SubSections.General_Update_From_Physio,
      [Physiotherapy_Review_SubSectionsId.New_Aid_Walker]: Physiotherapy_Review_SubSections.New_Aid_Walker,
      [Physiotherapy_Review_SubSectionsId.New_Exercises]: Physiotherapy_Review_SubSections.New_Exercises,
      [Physiotherapy_Review_SubSectionsId.Mobility_ReAssessed]: Physiotherapy_Review_SubSections.Mobility_ReAssessed,
    },
    [SectionsId.Request_For_Items]: {
      [Request_For_Items_SubSectionsId.Clothing_Footwear]: Request_For_Items_SubSections.Clothing_Footwear,
      [Request_For_Items_SubSectionsId.Food_Items]: Request_For_Items_SubSections.Food_Items,
      [Request_For_Items_SubSectionsId.Personal_Care_Items]: Request_For_Items_SubSections.Personal_Care_Items,
      [Request_For_Items_SubSectionsId.Other]: Request_For_Items_SubSections.Other,
    },
    [SectionsId.Personal_Care]: {
      [Personal_Care_SubSectionsId.Change_In_Habits]: Personal_Care_SubSections.Change_In_Habits,
      [Personal_Care_SubSectionsId.Wearing_Of_Pads]: Personal_Care_SubSections.Wearing_Of_Pads,
      [Personal_Care_SubSectionsId.Change_In_Personal_Hygiene_Needs]: Personal_Care_SubSections.Change_In_Personal_Hygiene_Needs,
    },
    [SectionsId.Nutritional]: {
      [Nutritional_SubSectionsId.Nutritional]: Nutritional_SubSections.Nutritional,
    },
    [SectionsId.Specialist_Appointment]: {
      [Specialist_Appointment_SubSectionsId.Referral_To_Allied_Health_Professiona]: Specialist_Appointment_SubSections.Referral_To_Allied_Health_Professiona,
      [Specialist_Appointment_SubSectionsId.Referral_To_Specialist]: Specialist_Appointment_SubSections.Referral_To_Specialist,
    },
    [SectionsId.Return_From_Hospital]: {
      [Return_From_Hospital_SubSectionsId.Return_From_Hospital]: Return_From_Hospital_SubSections.Return_From_Hospital
    },
    [SectionsId.Other]: {
      [Other_SubSectionsId.Other]: Other_SubSections.Other
    },
    [SectionsId.Resident_Of_The_Day]: {
      [Resident_Of_The_Day_SubSectionsId.Resident_Of_The_Day]: Resident_Of_The_Day_SubSections.Resident_Of_The_Day
    }
  }
  
  return SubCategories[sectionId][subSectionId]
}

export function getSectionId(categoryName: Sections | ''): SectionsId | '' {
  const Categories = {
    [Sections.Fall]: SectionsId.Fall,
    [Sections.Infection]: SectionsId.Infection,
    [Sections.GP_Review_Medication_Change]: SectionsId.GP_Review_Medication_Change,
    [Sections.Incident]: SectionsId.Incident,
    [Sections.Change_To_Condition]: SectionsId.Change_To_Condition,
    [Sections.Medication_Incidents]: SectionsId.Medication_Incidents,
    [Sections.Pain]: SectionsId.Pain,
    [Sections.Physiotherapy_Review]: SectionsId.Physiotherapy_Review,
    [Sections.Request_For_Items]: SectionsId.Request_For_Items,
    [Sections.Personal_Care]: SectionsId.Personal_Care,
    [Sections.Nutritional]: SectionsId.Nutritional,
    [Sections.Specialist_Appointment]: SectionsId.Specialist_Appointment,
    [Sections.Other]: SectionsId.Other,
    [Sections.Resident_Of_The_Day]: SectionsId.Resident_Of_The_Day,
    [Sections.Return_From_Hospital]: SectionsId.Return_From_Hospital,
  }

  return categoryName ? Categories[categoryName] : ''
}

export function getSubSectionId(categoryName:Sections | '', subCategoryName: SubSections | ''): SubSectionsId | '' {
  const SubCategories = {
    [Sections.Fall]: {
      [Fall_SubSections.Fall]: Fall_SubSectionsId.Fall
    },
    [Sections.Infection]: {
      [Infection_SubSections.Urinary_Infection]: Infection_SubSectionsId.Urinary_Infection,
      [Infection_SubSections.Respiratory]: Infection_SubSectionsId.Respiratory,
      [Infection_SubSections.Wound]: Infection_SubSectionsId.Wound,
      [Infection_SubSections.Skin_Infection]: Infection_SubSectionsId.Skin_Infection,
      [Infection_SubSections.Cellulitis]: Infection_SubSectionsId.Cellulitis,
      [Infection_SubSections.Ear_Nose_Throat_ENT]: Infection_SubSectionsId.Ear_Nose_Throat_ENT,
      [Infection_SubSections.Covid_First_Update_To_Family]: Infection_SubSectionsId.Covid_First_Update_To_Family,
      [Infection_SubSections.Covid_Ongoing_Update]: Infection_SubSectionsId.Covid_Ongoing_Update,
      [Infection_SubSections.Eye]: Infection_SubSectionsId.Eye,
      [Infection_SubSections.Other]: Infection_SubSectionsId.Other,
    },
    [Sections.GP_Review_Medication_Change]: {
      [GP_Review_Medication_Change_SubSections.General_Review]: GP_Review_Medication_Change_SubSectionsId.General_Review,
      [GP_Review_Medication_Change_SubSections.Deterioration_Physical_Or_Cognitive]: GP_Review_Medication_Change_SubSectionsId.Deterioration_Physical_Or_Cognitive,
      [GP_Review_Medication_Change_SubSections.Medication_Review]: GP_Review_Medication_Change_SubSectionsId.Medication_Review,
      [GP_Review_Medication_Change_SubSections.Review_Post_Fall_Or_Incident]: GP_Review_Medication_Change_SubSectionsId.Review_Post_Fall_Or_Incident,
      [GP_Review_Medication_Change_SubSections.Other]: GP_Review_Medication_Change_SubSectionsId.Other,
    },
    [Sections.Incident]: {
      [Incident_SubSections.Bruising]: Incident_SubSectionsId.Bruising,
      [Incident_SubSections.Skin_Tear]: Incident_SubSectionsId.Skin_Tear,
      [Incident_SubSections.Pressure_Injury]: Incident_SubSectionsId.Pressure_Injury,
      [Incident_SubSections.Ulcer]: Incident_SubSectionsId.Ulcer,
      [Incident_SubSections.Absconding]: Incident_SubSectionsId.Absconding,
      [Incident_SubSections.Behaviour]: Incident_SubSectionsId.Behaviour,
      [Incident_SubSections.Choking]: Incident_SubSectionsId.Choking,
      [Incident_SubSections.Other]: Incident_SubSectionsId.Other,
    },
    [Sections.Change_To_Condition]: {
      [Change_To_Condition_SubSections.Change_To_Cognition]: Change_To_Condition_SubSectionsId.Change_To_Cognition,
      [Change_To_Condition_SubSections.Change_To_Mobility]: Change_To_Condition_SubSectionsId.Change_To_Mobility,
      [Change_To_Condition_SubSections.Change_To_Appetite]: Change_To_Condition_SubSectionsId.Change_To_Appetite,
      [Change_To_Condition_SubSections.Change_To_Behaviour_Mood]: Change_To_Condition_SubSectionsId.Change_To_Behaviour_Mood,
      [Change_To_Condition_SubSections.Weight_Change_Update]: Change_To_Condition_SubSectionsId.Weight_Change_Update,
      [Change_To_Condition_SubSections.Other_General_Update]: Change_To_Condition_SubSectionsId.Other_General_Update,
    },
    [Sections.Medication_Incidents]: {
      [Medication_Incidents_SubSections.Medication_Incidents]: Medication_Incidents_SubSectionsId.Medication_Incidents
    },
    [Sections.Pain]: {
      [Pain_SubSections.Pain]: Pain_SubSectionsId.Pain
    },
    [Sections.Physiotherapy_Review]: {
      [Physiotherapy_Review_SubSections.General_Update_From_Physio]: Physiotherapy_Review_SubSectionsId.General_Update_From_Physio,
      [Physiotherapy_Review_SubSections.New_Aid_Walker]: Physiotherapy_Review_SubSectionsId.New_Aid_Walker,
      [Physiotherapy_Review_SubSections.New_Exercises]: Physiotherapy_Review_SubSectionsId.New_Exercises,
      [Physiotherapy_Review_SubSections.Mobility_ReAssessed]: Physiotherapy_Review_SubSectionsId.Mobility_ReAssessed,
    },

    [Sections.Request_For_Items]: {
      [Request_For_Items_SubSections.Clothing_Footwear]: Request_For_Items_SubSectionsId.Clothing_Footwear,
      [Request_For_Items_SubSections.Food_Items]: Request_For_Items_SubSectionsId.Food_Items,
      [Request_For_Items_SubSections.Personal_Care_Items]: Request_For_Items_SubSectionsId.Personal_Care_Items,
      [Request_For_Items_SubSections.Other]: Request_For_Items_SubSectionsId.Other,
    },
    [Sections.Personal_Care]: {
      [Personal_Care_SubSections.Change_In_Habits]: Personal_Care_SubSectionsId.Change_In_Habits,
      [Personal_Care_SubSections.Wearing_Of_Pads]: Personal_Care_SubSectionsId.Wearing_Of_Pads,
      [Personal_Care_SubSections.Change_In_Personal_Hygiene_Needs]: Personal_Care_SubSectionsId.Change_In_Personal_Hygiene_Needs,
    },
    [Sections.Nutritional]: {
      [Nutritional_SubSections.Nutritional]: Nutritional_SubSectionsId.Nutritional,
    },
    [Sections.Specialist_Appointment]: {
      [Specialist_Appointment_SubSections.Referral_To_Allied_Health_Professiona]: Specialist_Appointment_SubSectionsId.Referral_To_Allied_Health_Professiona,
      [Specialist_Appointment_SubSections.Referral_To_Specialist]: Specialist_Appointment_SubSectionsId.Referral_To_Specialist,
    },
    [Sections.Return_From_Hospital]: {
      [Return_From_Hospital_SubSections.Return_From_Hospital]: Return_From_Hospital_SubSectionsId.Return_From_Hospital
    },
    [Sections.Other]: {
      [Other_SubSections.Other]: Other_SubSectionsId.Other
    },
    [Sections.Resident_Of_The_Day]: {
      [Resident_Of_The_Day_SubSections.Resident_Of_The_Day]: Resident_Of_The_Day_SubSectionsId.Resident_Of_The_Day
    }
  }

  return (categoryName && subCategoryName) ? SubCategories[categoryName][subCategoryName] : ''
}

export function getPriorityId(priorityName: UpdatePriorities | ''): UpdatePrioritiesId | '' {
  const priority = {
    [UpdatePriorities.Low]: UpdatePrioritiesId.Minor,
    [UpdatePriorities.Medium]: UpdatePrioritiesId.Major,
    [UpdatePriorities.High]: UpdatePrioritiesId.Critical,
    [UpdatePriorities.Social]: UpdatePrioritiesId.Social,
  }

  return priorityName ? priority[priorityName] : ''
}

export function getPriorityNameById(priorityId: UpdatePrioritiesId): UpdatePriorities {
  const priority = {
    [UpdatePrioritiesId.Minor]: UpdatePriorities.Low,
    [UpdatePrioritiesId.Major]: UpdatePriorities.Medium,
    [UpdatePrioritiesId.Critical]: UpdatePriorities.High,
    [UpdatePrioritiesId.Social]: UpdatePriorities.Social
  }

  return priority[priorityId]
}

export function generateAdditionalInfo(mainValues: IMainUpdateForm, formSteps: Array<IUpdateStep>) {
  return {
    inputValues: getStepsValues(formSteps)
  }
}

export function generateMessageString(valuesMain: IMainUpdateForm, inputsValues: IUpdateValues, isMultiple: boolean = false) {
  return isMultiple ? UpdateSchema[valuesMain.update][valuesMain.subCategory].messageMultiple(valuesMain, inputsValues) : UpdateSchema[valuesMain.update][valuesMain.subCategory].message(valuesMain, inputsValues);
}

export function formatMessage(message?: string) {

  return message && message.split(UpdateMessageSymbols.Line_Break).map((paragraph) => {
    return (<p className={styles.paragraph}>{paragraph}</p>)
  })
}

export function formatMessageCopy(message: string) {
    return message.replaceAll(UpdateMessageSymbols.Line_Break, '\n')
}

export function getCategoriesString(section: Sections | '', subSection: SubSections | '') {
  return `${getSectionId(section)}/${getSubSectionId(section, subSection)}`
}

export function hydrateSchema(section:Sections, subSection:SubSections, update?) {
  if(!update.additionalInfo?.inputValues) {
    return UpdateSchema[section][subSection];
  } 

  return {
    ...UpdateSchema[section][subSection],
    steps: UpdateSchema[section][subSection].steps.map((step: IUpdateStep, i) => {
      return {
        ...step,
        inputs: step.inputs.map((input, j) => {
          return({
          ...formateInput(input, update),
          validationSchema: UpdateSchema[section][subSection].steps[i].inputs[j].validationSchema || undefined
        })})
      }
    })
  } 
}

export function formateInput(input: UpdateInput, update?) {
  const values = update.additionalInfo.inputValues;
  if(!values) {
    return input
  }

  const cloneInput = deepClone(input);

  const formateInputValue = (input: UpdateInput, update) => {
    switch(input.type) {
      case UpdateInputsTypes.Checkbox: {
        return {...input, checked: values[input.name]}
      }
      case UpdateInputsTypes.CheckboxInput: {
        if(input.dependentInputs) {
          for(let key in input.dependentInputs) {
            input.dependentInputs[key] = input.dependentInputs[key].map((input) => formateInputValue(input, update))
          }
        }

        return {...input, value: values[input.name]}
      }
      case UpdateInputsTypes.RadioInput: {
        if(input.dependentInputs) {
          for(let key in input.dependentInputs) {
            input.dependentInputs[key] = input.dependentInputs[key].map((input) => formateInputValue(input, update))
          }
        }

        return {...input, value: values[input.name]}
      }
      case UpdateInputsTypes.File: {
        return {...input, value: values[input.name]}
      }
      default: {
        return {...input, value: values[input.name]}
      }
    }
  }

  return formateInputValue(cloneInput, update);
}

export function getStepsValues(steps: Array<IUpdateStep>) {
  const getInputsValues = (inputs: UpdateInputs) => {
    let values: IUpdateValues = {};

    inputs.forEach((input) => {
      const {type, name, value} = input;

      switch(type) {
        case UpdateInputsTypes.Checkbox: {
          values[name] = !!input.checked;
          break;
        }
        case UpdateInputsTypes.CheckboxInput: {
          if(input.dependentInputs) {
            Object.values(input.dependentInputs).forEach((inputs) => {
              values = {...values, ...getInputsValues(inputs)}
            })
          }
          values[input.name] = value;
          break;
        }
        case UpdateInputsTypes.RadioInput: {
          if(input.dependentInputs) {
            Object.values(input.dependentInputs).forEach((inputs) => {
              values = {...values, ...getInputsValues(inputs)}
            })
          }
          values[input.name] = value;
          break;
        }
        case UpdateInputsTypes.File: {
          values[input.name] = value;
          break;
        }
        default: {
          if (typeof value === 'string') {
            values[name] = value.trim();
          } else {
            values[name] = value;
          }
        }
      }
    })

    return values
  }

  let values: IUpdateValues = {};
  steps.forEach((step) => {
    values = {...values, ...getInputsValues(step.inputs)}
  });

  return values;
}

export function completeStep(steps: Array<IUpdateStep>, stepFormikValues: IUpdateValues, stepNum: number) {
  return steps.map((formStep, i) => {
    if(i !== stepNum) {
      return formStep;
    } else {
      const completeInputs = (inputs: UpdateInputs) => {
        return inputs.map((input) => {
          switch(input.type) {
            case UpdateInputsTypes.CheckboxInput: {
              if(input.dependentInputs) {
                for(let key in input.dependentInputs) {
                  input.dependentInputs[key] = completeInputs(input.dependentInputs[key])
                }

                return ({ 
                  ...input, 
                  value: stepFormikValues[input.name],
                })
              }

              return {...input, value: stepFormikValues[input.name] }
            }
            case UpdateInputsTypes.RadioInput: {
              if(input.dependentInputs) {
                for(let key in input.dependentInputs) {
                  input.dependentInputs[key] = completeInputs(input.dependentInputs[key])
                }

                return ({ 
                  ...input, 
                  value: stepFormikValues[input.name],
                })
              }

              return {...input, value: stepFormikValues[input.name] }
            }
            case UpdateInputsTypes.Checkbox: {
              return ({ 
                ...input, 
                checked: stepFormikValues[input.name]
              })
            }
            default: {
              return ({ 
                ...input, 
                value: stepFormikValues[input.name]
              })
            }
          }
        })
      }

      return {...formStep, inputs: completeInputs(formStep.inputs)}
    }
  })
}

export function getTimePrefix(section: Sections | '', subSection: SubSections | '', preview?: boolean) {
  if(preview) {
    switch(section) {
      case Sections.Pain: return 'It started on: ';
      case Sections.Infection: return 'It started on: ';
      default: return 'This happened: '
    }
  } else {
    switch(section) {
      case Sections.Pain: return 'When it started: ';
      case Sections.Infection: return 'When it started: ';
      default: return 'When it happened: '
    }
  }
}

export function formatSummaryMessage(summary: string) {
  let formatedStr = summary.replace(/\.\s*\./g, '.')
                            .replace(/\n/g, '').replace(/ +/g, ' ')
                            .replaceAll(UpdateMessageSymbols.Line_Break, '\n')
                            .replaceAll(UpdateMessageSymbols.Paragraph_Break, '/n')
                            .replace(/\/n +/g, UpdateMessageSymbols.Line_Break).trim()
        
  const formatedStrArr = formatedStr.split(UpdateMessageSymbols.Line_Break);

  if(formatedStrArr.length > 1 && !formatedStrArr[formatedStrArr.length-1]) {
    formatedStrArr.pop();
    formatedStr = formatedStrArr.join(UpdateMessageSymbols.Line_Break)
  }

  return formatedStr;
}